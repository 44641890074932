import React, { useEffect, useState } from 'react';
import css from './index.module.css';
import SwitcherOutlined from '../../../components/SwitcherOutlined/SwitcherOutlined';
import CampaignMarketplace from './components/CampaignMarketplace';
import CollabreateCard from './components/CollabreateCard';
import VirtualAssistant from './components/VirtualAssistant';
import Mentorship from './components/Mentorship';
import JobBoard from './components/JobBoard';

const OurOfferings = () => {
  const [selectedTab, setSelectedTab] = useState('marketplace');

  const renderContent = () => {
    return (
      <>
        {selectedTab === 'marketplace' && <CampaignMarketplace />}
        {selectedTab === 'card' && <CollabreateCard />}
        {selectedTab === 'va' && <VirtualAssistant />}
        {selectedTab === 'creatorCoach' && <Mentorship />}
        {selectedTab === 'jobBoard' && <JobBoard />}
      </>
    );
  };

  return (
    <div className={css.container}>
      <div className={css.title}>Our Offerings</div>
      <div style={{ marginTop: 16 }}>
        <SwitcherOutlined
          activeKey={selectedTab}
          setActiveKey={setSelectedTab}
          items={[
            {
              label: 'Campaign Marketplace',
              key: 'marketplace',
            },
            {
              label: 'Creator Coach',
              key: 'creatorCoach',
            },
            {
              label: 'Collabreate Card',
              key: 'card',
            },
            // {
            //   label: 'Virtual Assistant',
            //   key: 'va',
            // },
            {
              label: 'Job Board',
              key: 'jobBoard',
            }
          ]}
        />
      </div>
      <div style={{ marginTop: 32 }}>{renderContent()}</div>
    </div>
  );
};

export default OurOfferings;
