import { useFormik } from "formik";
import ModalV2Simple from "../../ModalV2/ModalV2Simple";
import css from './CreatorDemoDialog.module.css';
import { HEAR_FROM_OPTIONS } from "../../../util/enums";
import Loading from "../../Loading/Loading";
import { useState } from "react";
import * as Yup from "yup";
import { camelToLabel } from "../../../util/string";
import { BlackButton } from "../../Button/Button";
import toast from "react-hot-toast";
import axiosInstance from "../../../axios";

export const CreatorDemoDialog = ({
  open,
  onClose
}) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      hearFrom: '',
      instagram: '',
      tiktok: ''
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string(),
      phone: Yup.string(),
      email: Yup.string().email('Invalid email address').required('Required'),
      hearFrom: Yup.string().required('Required'),
      instagram: Yup.string(),
      tiktok: Yup.string()
    }),
    onSubmit: async (values) => {
      if (formik.values.tiktok === '' && formik.values.instagram === '') {
        return;
      }
      await handleSendDemoRequest();
    }
  })

  const handleSendDemoRequest = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post('/slack/send-demo-creator-notification', formik.values);
      const { error, message } = response.data;

      if(error) {
        toast.error(message);
      } else {
        toast.success('Demo request sent successfully');
        onClose();
        formik.resetForm();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  if (!open) return null;

  return (
    <ModalV2Simple
      isOpen={open}
      onClose={onClose}
      title="Book a Demo"
      width={500}
    >
      <div className={css.formContainer}>
        <div className={css.grid_2}>
          <div>
            <div>First Name</div>
            <input type="text" name={"firstName"} value={formik.values.firstName} onChange={formik.handleChange} placeholder="First Name" className={css.inputText} />
            <div className={css.errorMessage}>{formik.errors.firstName}</div>
          </div>
          <div>
            <div>Last Name</div>
            <input type="text" name={"lastName"} value={formik.values.lastName} onChange={formik.handleChange} placeholder="Last Name" className={css.inputText} />
            <div className={css.errorMessage}>{formik.errors.lastName}</div>
          </div>
          <div>
            <div>Phone (Optional)</div>
            <input type="text" name={"phone"} value={formik.values.phone} onChange={formik.handleChange} placeholder="Phone" className={css.inputText} />
            <div className={css.errorMessage}>{formik.errors.phone}</div>
          </div>
          <div>
            <div>Email</div>
            <input type="text" name={"email"} value={formik.values.email} onChange={formik.handleChange} placeholder="Email" className={css.inputText} />
            <div className={css.errorMessage}>{formik.errors.email}</div>
          </div>
        </div>
        <div>
          <div>How did you hear about Collabreate</div>
          <select className={css.inputSelect} name='hearFrom' value={formik.values.hearFrom} onChange={formik.handleChange}>
            <option value={""} selected disabled>Select</option>
            {
              HEAR_FROM_OPTIONS.map((item, index) => (
                <option key={index} value={item}>{camelToLabel(item)}</option>
              ))
            }
          </select>
          <div className={css.errorMessage}>{formik.errors.hearFrom}</div>
        </div>
        <div>
          <div className={css.grid_2}>
            <div>
              <div>Tiktok</div>
              <input type='text' name='tiktok' onChange={formik.handleChange} value={formik.values.tiktok} className={css.inputText} />
            </div>
            <div>
              <div>Instagram</div>
              <input type='text' name='instagram' onChange={formik.handleChange} value={formik.values.instagram} className={css.inputText} />
            </div>
          </div>
          <div className={css.errorMessage}>{formik.values.tiktok === '' && formik.values.instagram === '' && "Either Instagram / Tiktok must be provided."}</div>
        </div>
        <div>
          <BlackButton onClick={formik.handleSubmit}>
            <div className={css.buttonTextWithLoading}>
              Submit
              {loading && <Loading />}
            </div>
          </BlackButton>
        </div>
      </div>
    </ModalV2Simple>
  )
}
