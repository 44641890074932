const checkIsCreator = protectedData => {
  if (protectedData?.isAgency || protectedData?.isBrand) {
    return false;
  }

  if (protectedData?.isCreator) {
    return true;
  }
};

export default checkIsCreator;
