import ModalV2Simple from "../../ModalV2/ModalV2Simple";
import toast from "react-hot-toast";
import Loading from "../../Loading/Loading";
import { BlackButton } from "../../Button/Button";
import { useEffect, useState } from "react";
import css from './MessageUploadFileDialog.module.css';
import { postUploadVideo, uploadFile, uploadImage } from "../../../util/api";

import uploadIcon from '../../../assets/icons/upload.svg';
import fileIcon from '../../../assets/icons/file.svg';

export const MessageUploadFileDialog = ({
  open,
  onClose,
  onSubmit
}) => {
  const ALLOWED_FILE_TYPES = ['image', 'video', 'pdf'];

  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile)
      setFilePreview(objectUrl)
    }

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(filePreview)
  }, [selectedFile])

  const onClickUpload = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = false;
    input.onchange = (input) => {
      const targetFile = input.target.files[0];
      if (ALLOWED_FILE_TYPES.some((key) => targetFile.type.includes(key))) {
        setErrorMessage('');
        setSelectedFile(targetFile);
      } else {
        setSelectedFile(null);
        setFilePreview(null);
        setErrorMessage(`${targetFile.name} has unsupported file type`);
      }
    }

    input.click();
  }

  const handleUploadFile = async () => {
    try {
      setLoading(true);

      const formData = new FormData();
      let resultLink = null;

      if (selectedFile.type.includes('image')) {
        formData.append('file', selectedFile);
        formData.append('id', `${Date.now()}`);

        const result = await uploadImage(formData);
        resultLink = result.url;
      } else if (selectedFile.type.includes('video')) {
        formData.append('videos', selectedFile);
        formData.append('id', `${Date.now()}`);

        const result = await postUploadVideo(formData);
        resultLink = result.urls[0];
      } else if (selectedFile.type.includes('pdf')) {
        formData.append('file', selectedFile);
        formData.append('id', `${Date.now()}`);

        const result = await uploadFile(formData);
        resultLink = result.url;
      } else {
        toast.error(`${selectedFile.name} has unsupported file type`);
        setErrorMessage(`${selectedFile.name} has unsupported file type`);
      }
      setErrorMessage('');
      return resultLink;
    } catch (error) {
      console.error('Error uploading file', error);
      setErrorMessage(error);
    } finally {
      setLoading(false);
    }
  }

  const onClickSubmit = async () => {
    const fileUrl = await handleUploadFile();
    if (fileUrl) {
      onSubmit(fileUrl);
      setSelectedFile(null);
      onClose();
    }
  }

  if (!open) {
    return null;
  }

  return (
    <ModalV2Simple
      isOpen={open}
      onClose={onClose}
      width={500}
      title={"Upload a file"}
    >
      <div className={css.fileInputContainer}>
        {!selectedFile ? (
          <div className={css.fileInput} onClick={onClickUpload}>
            <img src={uploadIcon} />
            <div>Upload a file</div>
          </div>
        ) : (
          <div className={css.uploadedContainer}>
            <BlackButton
              className={css.chooseAnotherBtn}
              onClick={onClickUpload}
              disabled={loading}
            >
              Choose another file
            </BlackButton>
            <div className={css.previewContainer}>
              {selectedFile.type.includes('image') && (
                <img src={filePreview} className={css.previewImage} />
              )}
              {selectedFile.type.includes('video') && (
                <video src={filePreview} className={css.previewVideo} controls />
              )}
              {selectedFile.type.includes('pdf') && (
                <div className={css.previewFile}>
                  <img src={fileIcon} className={css.fileIcon} />
                </div>
              )}
            </div>
            <div>{selectedFile.name}</div>
          </div>
        )}
        <div className={css.errorMessage}>{errorMessage}</div>
      </div>
      <div>
        <BlackButton
          onClick={onClickSubmit}
          disabled={!selectedFile || loading}
        >
          <div className={css.btnText}>
            <div>{loading ? "Uploading File" : "Send File"}</div>
            {loading && <Loading />}
          </div>
        </BlackButton>
      </div>
    </ModalV2Simple>
  )

};
