import css from './Chip.module.css';

export const Chip = ({
  children,
  color = 'default',
}) => {
  let colorClass = css.default;
  if (color === 'success') {
    colorClass = css.success
  } else if (color === 'warning') {
    colorClass = css.warning
  } else if (color === 'error') {
    colorClass = css.error
  }

  return (
    <div className={`${css.root} ${colorClass}`}>
      {children}
    </div>
  )
}
