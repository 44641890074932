import React, { Component } from 'react';
import { string, bool, func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';

import {
  Form,
  FieldTextInput,
  SecondaryButtonInline,
  PrimaryButton,
  Button,
  IconClose,
} from '../../../components';

import css from './SendMessageForm.module.css';
import Input from '../ActivityFeed/Input';
import { PinkButton } from '../../../components/Button/Button';
import { MessageUploadFileDialog } from '../../../components/Dialogs/MessageUploadFileDialog/MessageUploadFileDialog';

const BLUR_TIMEOUT_MS = 100;

const IconSendMessage = () => {
  return (
    <svg
      className={css.sendIcon}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className={css.strokeMatter} fill="none" fillRule="evenodd" strokeLinejoin="round">
        <path d="M12.91 1L0 7.003l5.052 2.212z" />
        <path d="M10.75 11.686L5.042 9.222l7.928-8.198z" />
        <path d="M5.417 8.583v4.695l2.273-2.852" />
      </g>
    </svg>
  );
};

class SendMessageFormComponent extends Component {
  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.blurTimeoutId = null;
  }

  handleFocus() {
    this.props.onFocus();
    window.clearTimeout(this.blurTimeoutId);
  }

  handleBlur() {
    // We only trigger a blur if another focus event doesn't come
    // within a timeout. This enables keeping the focus synced when
    // focus is switched between the message area and the submit
    // button.
    this.blurTimeoutId = window.setTimeout(() => {
      this.props.onBlur();
    }, BLUR_TIMEOUT_MS);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            messagePlaceholder,
            handleSubmit,
            inProgress,
            sendMessageError,
            invalid,
            form,
            formId,
            values,
            showFileInput,
            closeFileInput,

            clearFileInput,
            onVideoMessageSubmit,
            onRevisionMessage,
            setUrl,
          } = formRenderProps;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const submitDisabled = invalid || submitInProgress;
          return (
            <Form className={classes} onSubmit={values => handleSubmit(values, form)}>
              <div className={css.inputContainer}>
                <FieldTextInput
                  inputRootClass={css.textarea}
                  type="textarea"
                  id={formId ? `${formId}.message` : 'message'}
                  name="message"
                  placeholder="Message"
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                />
              </div>

              <div className={css.submitContainer}>
                {sendMessageError ? (
                  <div className={css.errorContainer}>
                    <p className={css.error}>
                      <FormattedMessage id="SendMessageForm.sendFailed" />
                    </p>
                  </div>
                ) : null}

                <PrimaryButton
                  className={css.submitButton}
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="heroicons-mini/paper-airplane">
                      <path
                        id="Subtract"
                        d="M2.79464 2.05955C2.56816 2.02745 2.34084 2.11223 2.19069 2.28478C2.04055 2.45734 1.988 2.6942 2.0511 2.91407L3.32339 7.34735C3.4895 7.92615 4.01885 8.32495 4.62101 8.32495H10.1249C10.4977 8.32495 10.7999 8.62716 10.7999 8.99995C10.7999 9.37274 10.4977 9.67495 10.1249 9.67495H4.62102C4.01886 9.67495 3.4895 10.0738 3.3234 10.6526L2.0511 15.0859C1.988 15.3057 2.04055 15.5426 2.19069 15.7151C2.34084 15.8877 2.56816 15.9725 2.79464 15.9404C8.04863 15.1957 12.7988 12.8868 16.5584 9.50158C16.7006 9.37358 16.7817 9.19126 16.7817 8.99996C16.7817 8.80866 16.7006 8.62635 16.5584 8.49834C12.7988 5.11312 8.04863 2.80422 2.79464 2.05955Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                </PrimaryButton>
              </div>
              <MessageUploadFileDialog
                open={showFileInput}
                onClose={closeFileInput}
                onSubmit={(fileUrl) => {
                  this.props.onSubmit({ message: fileUrl }, {});
                }}
              />
            </Form>
          );
        }}
      />
    );
  }
}

SendMessageFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  messagePlaceholder: null,
  onFocus: () => null,
  onBlur: () => null,
  sendMessageError: null,
};

SendMessageFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  messagePlaceholder: string,
  onSubmit: func.isRequired,
  onFocus: func,
  onBlur: func,
  sendMessageError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SendMessageForm = compose(injectIntl)(SendMessageFormComponent);

SendMessageForm.displayName = 'SendMessageForm';

export default SendMessageForm;
