import { isEmpty } from "lodash";
import axiosInstance from "../axios";

export default function useUserMigrationHook () {

  const checkUserExistByUserId = async (userId) => {
    try {
      const response = await axiosInstance.get('/user/detail/' + userId);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  const copySharetribeUser = async (sharetribeUser) => {
    try {
      let userType = sharetribeUser.attributes.profile.publicData.userType;
      if (!userType) {
        if (sharetribeUser.attributes.profile.protectedData.isCreator) {
          userType = "creator";
        }
        if (sharetribeUser.attributes.profile.protectedData.isClient) {
          userType = "client";
        }
        if (sharetribeUser.attributes.profile.protectedData.isBrand) {
          userType = "brand";
        }
      }

      // need to implement client data
      const newUserBody = {
        id: sharetribeUser.id.uuid,
        email: sharetribeUser.attributes.email,
        emailVerified: sharetribeUser.attributes.emailVerified,
        avatar: sharetribeUser.profileImage?.attributes.variants["square-small2x"].url,
        password: "",
        name: sharetribeUser.attributes.profile.displayName,
        bio: sharetribeUser.attributes.profile.bio,
        company: sharetribeUser.attributes.profile.publicData.company,
        displayName: sharetribeUser.attributes.profile.publicData.displayName,
        userType: userType,
        dob: sharetribeUser.attributes.profile.publicData.DOB,
        activity: sharetribeUser.attributes.profile.publicData.activity,
        gender: sharetribeUser.attributes.profile.publicData.gender,
        hearedAbout: sharetribeUser.attributes.profile.publicData.hearedAbout,
        languages: sharetribeUser.attributes.profile.publicData.languages ?? [],
        nationality: sharetribeUser.attributes.profile.publicData.nationality,
        location: sharetribeUser.attributes.profile.publicData.usCitizen,
        niches: sharetribeUser.attributes.profile.publicData.niches ?? [],
        phone: sharetribeUser.attributes.profile.protectedData.phoneNumber,
        phoneCountryCode: sharetribeUser.attributes.profile.protectedData.phoneNumberCountryCode,
        address: sharetribeUser.attributes.profile.privateData.homeAddress,
        pricipleName: "",
        twitter: sharetribeUser.attributes.profile.publicData.twitter,
        facebook: sharetribeUser.attributes.profile.publicData.facebook,
        instagram: sharetribeUser.attributes.profile.publicData.instagram,
        tiktok: sharetribeUser.attributes.profile.publicData.tiktok,
        youtube: sharetribeUser.attributes.profile.publicData.youtube,
        website: sharetribeUser.attributes.profile.publicData.website,
        linkedIn: sharetribeUser.attributes.profile.publicData.linkedIn,
        clientHasAccess: sharetribeUser.attributes.profile.protectedData.hasAccess,
        clientRole: sharetribeUser.attributes.profile.publicData.role,
        favoriteList: [],
      }

      const response = await axiosInstance.post('/user/create', {
        data: newUserBody
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  const updateSharetribeUser = async (sharetribeUser) => {
    try {
      let userType = sharetribeUser.attributes.profile.publicData.userType;
      if (!userType) {
        if (sharetribeUser.attributes.profile.protectedData.isCreator) {
          userType = "creator";
        }
        if (sharetribeUser.attributes.profile.protectedData.isClient) {
          userType = "client";
        }
        if (sharetribeUser.attributes.profile.protectedData.isBrand) {
          userType = "brand";
        }
      }

      // need to implement client data
      const newUserBody = {
        id: sharetribeUser.id.uuid,
        email: sharetribeUser.attributes.email,
        emailVerified: sharetribeUser.attributes.emailVerified,
        avatar: sharetribeUser.profileImage?.attributes.variants["square-small2x"].url,
        password: "",
        name: sharetribeUser.attributes.profile.displayName,
        bio: sharetribeUser.attributes.profile.bio,
        company: sharetribeUser.attributes.profile.publicData.company,
        displayName: sharetribeUser.attributes.profile.publicData.displayName,
        userType: userType,
        dob: sharetribeUser.attributes.profile.publicData.DOB,
        activity: sharetribeUser.attributes.profile.publicData.activity,
        gender: sharetribeUser.attributes.profile.publicData.gender,
        hearedAbout: sharetribeUser.attributes.profile.publicData.hearedAbout,
        languages: sharetribeUser.attributes.profile.publicData.languages ?? [],
        nationality: sharetribeUser.attributes.profile.publicData.nationality,
        location: sharetribeUser.attributes.profile.publicData.usCitizen,
        niches: sharetribeUser.attributes.profile.publicData.niches ?? [],
        phone: sharetribeUser.attributes.profile?.protectedData?.phoneNumber,
        phoneCountryCode: sharetribeUser.attributes.profile?.protectedData?.phoneNumberCountryCode,
        address: sharetribeUser.attributes.profile?.privateData?.homeAddress,
        pricipleName: "",
        twitter: sharetribeUser.attributes.profile.publicData.twitter,
        facebook: sharetribeUser.attributes.profile.publicData.facebook,
        instagram: sharetribeUser.attributes.profile.publicData.instagram,
        tiktok: sharetribeUser.attributes.profile.publicData.tiktok,
        youtube: sharetribeUser.attributes.profile.publicData.youtube,
        website: sharetribeUser.attributes.profile.publicData.website,
        linkedIn: sharetribeUser.attributes.profile.publicData.linkedIn,
        clientHasAccess: sharetribeUser.attributes.profile.protectedData?.hasAccess,
        clientRole: sharetribeUser.attributes.profile.publicData.role,
        favoriteList: [],
      }

      const response = await axiosInstance.post(`/user/update/${sharetribeUser.id.uuid}`, {
        data: newUserBody
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  const checkUserExistAndCopy = async (sharetribeUser) => {
    if (isEmpty(sharetribeUser)) {
      return;
    }

    try {
      const response = await axiosInstance.get('/user/detail/' + sharetribeUser.id.uuid);
      const { data } = response.data;
      if (!data) {
        console.log("User not exist on DB, copying user");
        return await copySharetribeUser(sharetribeUser);
      } else {
        console.log("User already exist on DB, updating user data");
        return await updateSharetribeUser(sharetribeUser);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return {
    checkUserExistAndCopy,
  }
}
