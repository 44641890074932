import { useAtom } from "jotai"
import { useEffect } from "react";
import { socketAtom } from "../atoms/socket"

export const useSocket = () => {
  const [socket] = useAtom(socketAtom);

  useEffect(() => {
    socket.connect();
    return () => {
      socket.disconnect();
    };
  }, [socket]);

  return socket;
}
