import { atom } from "jotai";
import { io } from "socket.io-client";

const SOCKET_SERVER_URL = "https://api-collabreate.nanaspos.masuk.id";

export const socketAtom = atom(() => {
  const socket = io(SOCKET_SERVER_URL, {
    autoConnect: false
  });
  return socket;
})
