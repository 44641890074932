import css from './TextAreaField.module.css';

export const TextAreaField = ({
  id = null,
  name = null,
  value = null,
  onChange = null,
  placeholder = null,
  errorMessage = null,
}) => {
  return (
    <div>
      <textarea
        id={id}
        type={"text"}
        name={name}
        value={value}
        onChange={(e) => onChange(e)}
        placeholder={placeholder}
        className={`${css.baseContainer} ${css.baseFont}`}
      >
      </textarea>
      <div className={css.errorMessage}>{errorMessage}</div>
    </div>
  )
}
