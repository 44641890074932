import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import { ensureOwnListing } from '../../util/data';
import { SERVICES } from '../StepperFiles/ProSteppers/ProThirdStepFile';
import config from '../../config';
import { isVideo } from '../../util/videos';

const useStepperHook = props => {
  const {
    section,
    history,
    onImageUpload,
    createInitialValues,
    onCreateListing,
    onUpdateProfile,
    page,
    image,
    images,
    onUpsertListingDraft,
    onUpdateListing,
    getOwnListing,
    onAddAvailabilityException,
    currentUserData,
    onVideoUpload,
  } = props;
  const [selectedWhatSell, setSelectedWhatSell] = useState('');
  const [addOns, setAddOns] = useState(
    config.addOns.map(v => ({
      label: v,
      amount: 0,
      currency: 'SGD',
      default: true,
      id: v,
    }))
  );
  const [step, setStep] = useState(() => {
    if (typeof window !== 'undefined') {
      return JSON.parse(sessionStorage.getItem('step') || 0);
    }
    return 0;
  });

  const [keepedData, setKeepedData] = useState(() => {
    if (typeof window !== 'undefined') {
      return JSON.parse(sessionStorage.getItem('KeepedData'));
    }
    return 0;
  });

  useEffect(() => {
    const handlePopState = () => {
      setStep(prevStep => (prevStep > 1 ? prevStep - 1 : 0));
    };
    history.push('/signup');

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [step]);

  const [addOnsError, setAddOnsError] = useState();

  const handleAddOnsAmout = (addOn, event) => {
    // Get the new amount from the event
    const newAmount = parseInt(event.target.value);

    // Create a new array with the updated add-on
    const newAddOns = addOns.map(item => {
      if (item.id === addOn.id) {
        return { ...item, amount: newAmount };
      } else {
        return item;
      }
    });

    // Update the state
    setAddOns(newAddOns);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      sessionStorage.setItem('step', step);
      sessionStorage.setItem('KeepedData', JSON.stringify(props?.currentUser?.attributes));
    }
  }, [step, props?.currentUser?.attributes]);

  const handleNewAddons = () => {
    const newAddOns = {
      label: '',
      amount: 0,
      currency: 'SGD',
      default: false,
      id: new Date().getTime(),
    };
    setAddOns([...addOns, newAddOns]);
  };

  const handleAddonsLabel = (addOn, event) => {
    setAddOnsError(undefined);
    const newLabel = event.target.value;

    // Create a new array with the updated add-on
    const newAddOns = addOns.map(item => {
      if (item.id === addOn.id) {
        return { ...item, label: newLabel };
      } else {
        return item;
      }
    });

    // Update the state
    setAddOns(newAddOns);
  };

  const handleAddonsRemove = addOn => {
    // Create a new array without the add-on to be removed
    const newAddOns = addOns.filter(item => item.id !== addOn.id);

    // Update the state
    setAddOns(newAddOns);
  };

  // pro step2
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  function _handleLanguages(val) {
    if (_.includes(selectedLanguage, val?.value)) {
      const rejectedValue = _.reject(selectedLanguage, r => r === val?.value);
      setSelectedLanguage(rejectedValue);
    } else {
      setSelectedLanguage([...selectedLanguage, val?.value]);
    }
  }

  const [selectedNiches, setSelectedNiches] = useState([]);
  function handleNiches(val) {
    if (_.includes(selectedNiches, val?.value)) {
      const rejectedValue = _.reject(selectedNiches, r => r === val?.value);
      setSelectedNiches(rejectedValue);
    } else {
      setSelectedNiches([...selectedNiches, val?.value]);
    }
  }

  const [currentListingId, setCurrentListingId] = useState({});

  const inquireProductTitle = 'Open to gifted collaborations';

  const {
    createListingDraftError = null,
    publishListingError = null,
    updateListingError = null,
    showListingsError = null,
    uploadImageError = null,
    fetchExceptionsError = null,
    addExceptionError = null,
    deleteExceptionError = null,
  } = page;

  const errorsApis = {
    createListingDraftError,
    publishListingError,
    updateListingError,
    showListingsError,
    uploadImageError,
    fetchExceptionsError,
    addExceptionError,
    deleteExceptionError,
  };

  const INITIAL_VALUES = [
    {
      title: keepedData?.profile?.firstName,
      nationality: keepedData?.profile?.publicData.nationality,
      usCitizen: keepedData?.profile?.usCitizen,
      gender: keepedData?.profile?.publicData.gender,
      DOB: keepedData?.profile?.publicData.DOB,
      homeAddress: keepedData?.profile?.privateData.homeAddress,
      phoneNumber: keepedData?.profile?.protectedData.phoneNumber,
      hearFrom: keepedData?.profile?.publicData.hearedAbout,
      nricFin: keepedData?.profile?.publicData.niches,
    },
    {
      homeAddress: keepedData?.profile?.privateData.homeAddress,
      hearFrom: keepedData?.profile?.publicData.hearedAbout,
      displayName: keepedData?.profile?.displayName,
      role: keepedData?.profile?.role,
      about: keepedData?.profile?.about,
      tiktok: keepedData?.profile?.publicData.tiktok,
      instagram: keepedData?.profile?.publicData.instagram,
    },
    {},
    {
      discriptionTitle: '',
      category: '',
      ageCategory: '',
      description: '',
      shippingAddress: '',
      requestLocation: '',
      online: '',
      myLocation: '',
      location: { search: '', predictions: [], selectedPlace: null },
      location1: { search: '', predictions: [], selectedPlace: null },
      location2: { search: '', predictions: [], selectedPlace: null },
    },
  ];

  useEffect(() => {
    if (page.submittedListingId) {
      setCurrentListingId(page.submittedListingId);
    }
  }, [page.submittedListingId]);

  const LEARNER_VALIDATION = {
    displayName: Yup.string()
      .trim()
      .required('This field is required'),
    hearFrom: Yup.string()
      .trim()
      .required('This field is required'),
    role: Yup.string()
      .trim()
      .required('This field is required'),
    instagram: Yup.string()
      .trim()
      .optional(),
    tiktok: Yup.string()
      .trim()
      .optional(),
    linkedin: Yup.string()
      .trim()
      .optional(),
    bio: Yup.string()
      .trim()
      .required('This field is required'),
  };
  const learnerValidationSchema = Yup.object().shape(LEARNER_VALIDATION);

  const proValidationSchema = [
    Yup.object().shape({
      title: Yup.string()
        .trim()
        .required('This field is required'),
      phoneNumber: Yup.string()
        .trim()
        .required('This field is required'),

      gender: Yup.string()
        .trim()
        .required('This field is required'),
      nationality: Yup.string()
        .trim()
        .required('This field is required'),
      DOB: Yup.string()
        .trim()
        .required('This field is required'),
      homeAddress: Yup.string()
        .trim()
        .required('This field is required'),
      hearFrom: Yup.string().required('This field is required'),
      // nricFin: Yup.string()
      //   .trim()
      //   .required('This field is required'),
    }),
    Yup.object().shape({
      ..._.omit(LEARNER_VALIDATION, ['phoneNumber']),
      displayName: Yup.string()
        .trim()
        .required('This field is required'),
      role: Yup.string()
        .trim()
        .required('This field is required'),
      bio: Yup.string()
        .trim()
        .required('This field is required'),
      instagram: Yup.string()
        .trim()
        .required('This field is required'),
      tiktok: Yup.string()
        .trim()
        .required('This field is required'),
      hearFrom: Yup.string()
        .trim()
        .required('This field is required'),
      usCitizen: Yup.string()
        .trim()
        .required('This field is required'),
    }),
    null,
    Yup.object().shape({
      // discriptionTitle: Yup.string()
      //   .trim()
      //   .required('This field is required'),
      // category: Yup.string()
      //   .trim()
      //   .required('This field is required'),
      // ageCategory:
      //   productType === 'services'
      //     ? Yup.string()
      //         .trim()
      //         .required('This field is required')
      //     : Yup.string().trim(),
      description: Yup.string()
        .trim()
        .required('This field is required'),
      pricing_hourly:
        selectedWhatSell === inquireProductTitle
          ? null
          : Yup.number('Must be number')
              .required('This field is required')
              .min(6, 'Price should be at least SGD 6.00'),
      shippingAddress:
        selectedWhatSell === 'Open to gifted collaborations'
          ? Yup.string()
              .trim()
              .required('This field is required')
          : null,
      //  selectedWhatSell === 'Open to gifted collaborations' ? 'seeding' : 'test'
      // .trim()
      // .required('This field is required'),
      // requestLocation: Yup.string()
      //   .trim()
      //   .notRequired('This field is required'),
      // online: Yup.string()
      //   .trim()
      //   .notRequired('This field is required'),
      // myLocation: Yup.string()
      //   .trim()
      //   .notRequired('This field is required'),
    }),
    null,
  ];

  const STEPS =
    section === 'pro'
      ? [
          { title: 'Personal Info' },
          { title: 'Profile' },
          { title: 'Listing type' },
          { title: 'Listing Setup' },
        ]
      : [{ title: 'Personal Info' }];
  const isLastStep = step === STEPS.length - 1;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [step]);

  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  function _handleBack() {
    setStep(step - 1);
  }

  function _handleNext(section = 'learner') {
    if (section === 'learner' && isLastStep) {
      history.push('/campaigns');
    }
    //  else {
    //     console.log(isLastStep, step, step + 1, step + 1 > STEPS?.length)
    //     if ((isLastStep && step + 1 > STEPS?.length)) {
    //         history.push('/listings');
    //     }
    // }
    setStep(step + 1);
  }

  const [imageUpload, setImageUpload] = useState({
    preview: '',
    raw: '',
    errMsg: '',
  });
  const [listingImageUpload, setListingImageUpload] = useState({
    preview: '',
    raw: '',
    errMsg: '',
  });
  const inputRef = useRef(null);
  const listingInputRef = useRef(null);

  function handleClick() {
    inputRef.current.click();
  }
  function handleClickListing() {
    listingInputRef.current.click();
  }

  const onImageUploadHandler = (values, fn) => {
    const { id, imageId, file } = values;
    if (file) {
      fn({ id, imageId, file });
    }
  };

  const _ImageChange = (event, setImage) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      setImage({ preview: null, raw: null, errMsg: 'This field is required' });
      return;
    }

    const tempId = `${fileObj.name}_${Date.now()}`;

    if (isVideo(fileObj)) {
      const formData = new FormData();
      formData.append('id', tempId);
      formData.append('videos', fileObj);
      onVideoUpload(formData, res => {
        if (isLastStep && res.id) {
          setImagesIdsUpload([...imagesIdsTOUpload, res]);
        }
      });
    } else {
      setImage({ preview: URL.createObjectURL(fileObj), raw: fileObj });

      onImageUploadHandler({ id: tempId, file: fileObj }, data => {
        onImageUpload(data, resp => {
          if (isLastStep && resp?.id) {
            setImagesIdsUpload([...imagesIdsTOUpload, resp]);
          }
        });
      });
    }
    event.target.value = null;
  };

  const _handleImageChange = event => {
    _ImageChange(event, setImageUpload);
  };

  const _handleListingImageChange = event => {
    _ImageChange(event, setListingImageUpload);
  };

  const _handleRemoveMedia = media => {
    const newMediaItems = imagesIdsTOUpload.filter((item, index) => {
      if (typeof item.id === 'string') {
        return item.id !== media.id;
      } else {
        return item.id.uuid !== media.id.uuid;
      }
    });
    setImagesIdsUpload(newMediaItems);
  };

  // ************************************************ pro function *************************************
  const [calenderAvablility, setCalenderAvablility] = useState(null);
  const [priceValues, setPriceValues] = useState({ old: null, new: null });
  const [locationArray, setLocationArray] = useState(['location']);
  const [exceptionData, setExceptionData] = useState({
    listingId: null,
    seats: null,
    start: '',
    end: '',
  });

  const [imagesIdsTOUpload, setImagesIdsUpload] = useState([]);

  useEffect(() => {
    setListingImageUpload(prevState => {
      return { ...prevState, errMsg: '' };
    });
  }, [listingImageUpload?.raw, imagesIdsTOUpload]);

  const imageIds = images => {
    return images
      ? images.filter(img => img.type === 'image').map(img => img?.imageId || img?.id)
      : null;
  };

  const videosSelector = uploads => {
    return uploads
      ? uploads.filter(upload => upload?.urls?.length > 0).map(({ urls }) => urls[0])
      : null;
  };

  const transformAddOns = () => {
    const transformedValue = addOns.reduce((acc, cur) => {
      if (cur.amount > 0 && cur.label !== '') {
        acc[cur.label] = {
          amount: cur.amount,
          currency: cur.currency,
        };
      }
      return acc;
    }, {});

    return transformedValue;
  };

  async function _submitProForm(values, actions) {
    try {
      await _sleep(1000);
      if (step === 1) {
        if (!imageUpload?.raw) {
          setImageUpload({ preview: null, raw: null, errMsg: 'This field is required' });
          return;
        }
      }
      if (step === 3) {
        const labels = addOns.map(item => item.label);
        const duplicates = labels.filter((item, index) => labels.indexOf(item) != index);

        if (duplicates.length > 0) {
          setAddOnsError(`Duplicate labels found: ${duplicates.join(', ')}`);
          return;
        }

        if (!listingImageUpload?.raw && !videosSelector(imagesIdsTOUpload).length) {
          setListingImageUpload({ preview: null, raw: null, errMsg: 'This field is required' });
          return;
        }
      }

      const {
        title,
        usCitizen,
        gender,
        DOB,
        nationality,
        // nricFin,
        hearFrom,
        displayName,
        role,
        about,
        otherLanguages,
        pricing_hourly,
      } = values;

      const listOtherLanguages = otherLanguages?.split(',') || [];

      if (isLastStep) {
        const {
          location,
          location2,
          location3,
          discriptionTitle,
          description,
          shippingAddress,
          category,
          ageCategory,
          online,
        } = values;

        const address = location && location.selectedPlace && location.selectedPlace.address;
        const origin = location && location.selectedPlace && location.selectedPlace.origin;

        const address2 = location2 && location2.selectedPlace && location2.selectedPlace.address;
        const origin2 = location2 && location2.selectedPlace && location2.selectedPlace.origin;

        const address3 = location3 && location3.selectedPlace && location3.selectedPlace.address;
        const origin3 = location3 && location3.selectedPlace && location3.selectedPlace.origin;

        // listing values
        const VALUES_UPDATE = {
          title: discriptionTitle || selectedWhatSell,
          description: description,

          price: {
            _sdkType: 'Money',
            amount: +pricing_hourly * 100,
            currency: 'SGD',
          },
          availabilityPlan: calenderAvablility?.availabilityPlan,
          publicData: {
            usCitizen,
            gender,

            nationality: usCitizen,
            DOB,
            // nricFin,
            // authorId: currentUserData?.uuid,
            displayName: displayName || '',
            activity: [role] || '',
            about: about || '',
            certificate: null,
            allowedToWork: 'no',
            convicted: 'no',
            ageCategory: [ageCategory],
            category: [category],
            // "activity": [
            //     "personal training",
            //     "football"
            // ],
            activityOther: null,
            sports: null,
            achievements: null,
            specializations: null,
            exclusiveOnlineTraining: online ? 'yes' : 'no',
            virtualTraining: null,
            travelWilling: null,
            transactionProcessAlias:
              selectedWhatSell === inquireProductTitle
                ? 'default-inquiry/release-1'
                : 'default-purchase/release-1',
            listingType: selectedWhatSell === inquireProductTitle ? 'seeding' : 'test', // TO DO: change it to the fetched type
            unitType: selectedWhatSell === inquireProductTitle ? 'inquiry' : 'item',
            location: { address, building: null },
            location2: {
              address: address2 || null,
              buildingAddress: null,
              origin: origin2 || null,
            },
            location3: {
              address: address3 || null,
              buildingAddress: null,
              origin: origin3 || null,
            },
            videos: videosSelector(imagesIdsTOUpload),
            addOns: transformAddOns(),
            languages: [...selectedLanguage, ...listOtherLanguages],
            niches: selectedNiches,
          },
          privateData: {
            hearedAbout: hearFrom || '',
            grantProleadDiscretion: null,
            shippingAddress,
          },
          images: typeof listingImageUpload !== 'undefined' ? imageIds(imagesIdsTOUpload) : {},
        };
        const VALUES_DATA =
          origin && !online ? { ...VALUES_UPDATE, geolocation: origin } : VALUES_UPDATE;
        onCreateListing(VALUES_DATA, data => {
          setTimeout(() => {
            if (exceptionData?.start) {
              onAddAvailabilityException({ ...exceptionData, listingId: data?.data?.data?.id })
                .then(() => {
                  window.location.assign(`/creator/${currentUserData?.uuid}`);
                })
                .catch(err => alert('somthing went wrong try again'));
            } else {
              window.location.assign(`/creator/${currentUserData?.uuid}`);
            }
          }, 1000);
          // actions.setSubmitting(false);
          // setStep(step + 1);
        }).catch(err => alert('somthing went wrong try again'));
      } else {
        const uploadedImage = props?.image;

        const {
          firstName,
          lastName,
          title,
          usCitizen,
          phoneCountryCode,
          phoneNumber,
          gender,
          DOB,
          nationality,
          homeAddress,
          // nricFin,
          hearFrom,
          displayName,
          role,
          about,
          otherLanguages,
          tiktok,
          instagram,
          youtube,
          twitter,
          facebook,
          website,
        } = values;

        const listOtherLanguages = otherLanguages?.split(',') || [];

        // User Data
        const VALUES_UPDATE = {
          firstName: firstName || title || '',
          lastName: lastName || title || '',
          bio: about || '',
          publicData: {
            pricipleName: title,
            usCitizen,
            gender,
            nationality,
            DOB,
            hearedAbout: hearFrom || '',
            displayName: displayName || '',
            activity: role || '',
            languages: [...selectedLanguage, ...listOtherLanguages],
            niches: selectedNiches,
            tiktok,
            instagram,
            youtube,
            twitter,
            facebook,
            website,
          },
          protectedData: {
            // nricFin,
            preferredName: displayName,
            phoneNumberCountryCode: '+' + phoneCountryCode,
            phoneNumber: phoneNumber,
          },
          privateData: {
            homeAddress: homeAddress,
          },
        };

        const updatedValues =
          uploadedImage && uploadedImage.imageId && uploadedImage.file
            ? { ...VALUES_UPDATE, profileImageId: uploadedImage.imageId }
            : VALUES_UPDATE;

        onUpdateProfile(updatedValues);
        actions.setSubmitting(false);
        setStep(step + 1);
      }

      // if (step == 0 && !currentListing?.id) {
      //     console.log(values, VALUES_UPDATE);
      //     onCreateListingDraft(VALUES_UPDATE, () => {
      //         actions.setSubmitting(false);
      //         setStep(step + 1);
      //     })
      // } else {
      //     onUpdateListing(null, { ...upsertValues, id: currentListing.id }, 'signup');
      //     actions.setSubmitting(false);
      //     setStep(step + 1);
      // }
    } catch (err) {
      console.log(err);
    }
  }

  // ************************************************ Learner function *************************************

  // learner step 2
  const [selectedButton, setSelectedButton] = useState([]);
  function _handleInterested(val) {
    if (_.includes(selectedButton, val?.value)) {
      const rejectedValue = _.reject(selectedButton, r => r === val?.value);
      setSelectedButton(rejectedValue);
    } else {
      setSelectedButton([...selectedButton, val?.value]);
    }
  }

  // const handleUpdateProfile = updatedValues => {
  //   return new Promise((resolve, reject) => {
  //     onUpdateProfile(updatedValues);
  //     resolve();
  //   });
  // };

  async function _submitLearnerForm(values, actions) {
    await _sleep(1000);
    if (step === 0) {
      const uploadedImage = props?.image;
      if (!imageUpload?.raw) {
        setImageUpload({ preview: null, raw: null, errMsg: 'This field is required' });
        return;
      }

      // Update profileImage only if file system has been accessed
      const profile = {
        firstName: createInitialValues?.firstName.trim(),
        lastName: createInitialValues?.lastName.trim(),
        bio: values.bio,
        publicData: {
          displayName: values?.displayName,
          hearFrom: values?.hearFrom,
          role: values?.role,
          interested: selectedButton,
          tiktok: values?.tiktok,
          instagram: values?.instagram,
          linkedin: values?.linkedin,
        },
        protectedData: {
          phoneNumberCountryCode: values?.phoneCountryCode,
          phoneNumber: values?.phoneNumber,
        },
      };
      const updatedValues =
        uploadedImage && uploadedImage.imageId && uploadedImage.file
          ? { ...profile, profileImageId: uploadedImage.imageId }
          : profile;

      onUpdateProfile(updatedValues);
      setTimeout(() => {
        window.location.assign('/pricing');
      }, 1000);
      actions.setSubmitting(false);
      if (!isLastStep) {
        setStep(step + 1);
      }
    } else {
    }
  }

  return {
    // states
    locationArray,
    setLocationArray,
    step,
    setStep,
    inputRef,
    imageUpload,
    listingImageUpload,
    isLastStep,
    STEPS,
    selectedButton,
    setSelectedWhatSell,
    setCalenderAvablility,
    priceValues,
    setPriceValues,
    exceptionData,
    setExceptionData,
    selectedLanguage,
    selectedNiches,

    // functions
    _submitLearnerForm,
    _submitProForm,
    _handleBack,
    _handleNext,
    _handleImageChange,
    handleClick,
    _handleInterested,
    _handleListingImageChange,
    handleNiches,
    handleClickListing,
    _handleLanguages,
    // constants
    INITIAL_VALUES,
    errorsApis,
    listingInputRef,
    imagesIdsTOUpload,
    learnerValidationSchema,
    proValidationSchema,
    selectedWhatSell,
    _handleRemoveMedia,
    addOns,
    setAddOns,
    handleAddOnsAmout,
    handleNewAddons,
    handleAddonsLabel,
    handleAddonsRemove,
    addOnsError,
    ...props,
  };
};

export default useStepperHook;
