import React, { useState } from 'react';
import {
  Footer,
  LayoutSideNavigation,
  LayoutWrapperAccountSideNav,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Modal,
  Page,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from '../../util/reactIntl';

import message from './icon-messages.svg';
import calendar from './icon-calendar.svg';
import lightningIcon from './lightningIcon.svg';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import axios from 'axios';
import ServiceCard from './ServiceCardComponent/ServiceCardComponent';
import LoaderComponent from '../../components/LoaderComponent/LoaderComponent';
import ModalV2Standard from '../../components/ModalV2/ModalV2Standard';
import css from './CreatorServicesPage.module.css';
import { PinkButton } from '../../components/Button/Button';
import axiosInstance from '../../axios';
import toast from 'react-hot-toast';

export const CreatorServicesPageComponent = ({ intl }) => {
  const title = 'Creator services | Collabreate';

  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user?.currentUser) || {};
  const email = currentUser.attributes?.email;
  const { firstName, lastName } = currentUser?.attributes?.profile || {};

  const [isModalOpen, setIsmodalOpen] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [isError, setIsError] = useState(false);

  const onManageDisableScrolling = (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling));

  const handleCloseModal = () => {
    setIsmodalOpen(false);
  };

  const errorMessage = intl.formatMessage({
    id: 'CreatorServicesPage.sendRequestError',
  });

  const handleSendRequest = async (serviceTitle) => {
    try {
      setIsloading(true);
      setIsError(false);

      await Promise.allSettled([
        axiosInstance.post('/slack/send-creator-service-notification', {
          firstName,
          lastName,
          serviceTitle,
          email,
        }),
        axiosInstance.post('/creator-service/email', {
          creatorName: `${firstName} ${lastName}`,
          creatorEmail: email,
        })
      ]);

      toast.success("Service request sent successfully!");
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsloading(false);
    }
  };

  const services = [
    {
      id: 1,
      title: "Social Media Audit (TikTok)",
      price: "150 SGD (Early Bird 119 SGD)",
      subtitle: "I need help with growing my social media",
      info: "The Collabreate Team will review and strategize your social media media for high growth and monetization for both Instagram and TikTok",
      icon: message,
    },
    {
      id: 2,
      title: intl.formatMessage({
        id: 'CreatorServicesPage.coachingTitle',
      }),
      subtitle: "I need help with my blog content creation and business",
      info: "Our expert will assist you in your custom needs ranging from brand negotiations to providing ongoing strategy",
      icon: calendar,
    },
  ];

  return (
    <Page title={title} scrollingDisabled={false}>
      <LayoutSideNavigation containerClassName={css.sidebarContainer}>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="CreatorServicesPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSideNav
          currentTab="CreatorServicesPage"
          userProfile={true}
          isAvatar={true}
          isProUser={true}
        />
        <LayoutWrapperMain className={css.wrapper}>
          <h1 className={css.header}>
            {intl.formatMessage({
              id: 'CreatorServicesPage.title',
            })}
          </h1>
          <div className={css.subtitle}>
            With over 15 years of PR and marketing experience, our team brings a wealth of expertise as successful content creators and marketers. Our clients include top brands like SK-II and M.A.C. so we understand exactly what brands are looking for and what makes personalities and content stand out. We review hundreds of content daily, giving us a keen insight into what works and what doesn't.
          </div>
          <div className={css.subtitle}>
            Our goal is to provide you with actionable insights to achieve better content outcomes—whether it's increasing your follower count, boosting engagement, or improving your brand image. Let us help you fine-tune your strategy and unlock your full potential!
          </div>
          <div className={css.serviceCardsContainer}>
            {/* Option 1 */}
            <div className={css.serviceCard}>
              <div>
                <img src={message} alt="service icon" />
                <h2 className={css.cardHeader}>Social Media Audit (TikTok)</h2>
                <p className={css.cardSubHeader}>150 SGD (Early Bird 119 SGD)</p>
              </div>
              <div className={css.cardTextContainer}>
                <div>Our team will perform an in-depth analysis of your TikTok account, generating a comprehensive report covering your audience, content, and strategy.</div>
                <div className={css.detailContainer}>
                  <div>
                    <b>What's included:</b>
                  </div>
                  <ul className={css.list}>
                    <li className={css.detailText}><b>In-depth analysis report:</b> A detailed review of your page, including analysis of views, likes, and followers.</li>
                    <li className={css.detailText}><b>Target Audience Research:</b> Insights into your audience’s preferences, helping you understand what content resonates and how to engage them effectively.</li>
                    <li className={css.detailText}><b>Content Review:</b> We'll review 2–4 of your videos based on the strengths and weaknesses we identify.</li>
                    <li className={css.detailText}><b>Content Ideas:</b> We'll share 2–4 content ideas that you can use in your plan.</li>
                    <li className={css.detailText}><b>Feedback and Suggestions:</b> Personalized advice on how to improve and grow your account.</li>
                  </ul>
                </div>
              </div>
              <PinkButton
                className={css.requestButton}
                disabled={isLoading}
                onClick={() => {
                  handleSendRequest("Social Media Audit (TikTok)");
                }}
              >
                {intl.formatMessage({
                  id: 'CreatorServicesPage.requestButton',
                })}
              </PinkButton>
            </div>

            {/* Option 2 */}
            <div className={css.serviceCard}>
              <div>
                <img src={calendar} alt="service icon" />
                <h2 className={css.cardHeader}>Social Media Audit + 1:1 Call</h2>
                <p className={css.cardSubHeader}>450 SGD (Early Bird 299 SGD)</p>
              </div>
              <div className={css.cardTextContainer}>
                <div>In addition to a full social media audit across all your platforms, you’ll receive a 60-minute 1:1 call with our founder to discuss personalized insights and strategies. During this session, you can address any specific questions you may have about your account and growth.</div>
                <div className={css.detailContainer}>
                  <div>
                    <b>What's included:</b>
                  </div>
                  <ul className={css.list}>
                    <li className={css.detailText}><b>In-depth analysis report:</b> review of your page, including observations of views, likes, and followers.</li>
                    <li className={css.detailText}><b>Target Audience Research:</b> Insights into your audience’s preferences and how to engage them.</li>
                    <li className={css.detailText}><b>Video Review:</b> Review of 2–4 videos based on our findings.</li>
                    <li className={css.detailText}><b>Content Ideas:</b> Hooks, series, and filming tips.</li>
                    <li className={css.detailText}><b>Feedback and Suggestions:</b> Tailored recommendations to help you improve and grow.</li>
                  </ul>
                </div>
                <div className={css.detailContainer}>
                  <div>
                    <b>Discussion Topics for the 1:1 call:</b>
                  </div>
                  <ul className={css.list}>
                    <li className={css.detailText}>How to attract inbound interest from dream brands.</li>
                    <li className={css.detailText}>Curating your audience demographic (e.g., addressing a largely male audience).</li>
                    <li className={css.detailText}>Content strategy advice: hooks, series, and filming tips.</li>
                    <li className={css.detailText}>Insights into agency and brand processes, including creator pitching and selection.</li>
                    <li className={css.detailText}>Tailored content strategy, plan + unique content ideas</li>
                    <li className={css.detailText}>Q+A</li>
                  </ul>
                </div>
              </div>
              <PinkButton
                className={css.requestButton}
                disabled={isLoading}
                onClick={() => {
                  handleSendRequest("Social Media Audit + 1:1 Call");
                }}
              >
                {intl.formatMessage({
                  id: 'CreatorServicesPage.requestButton',
                })}
              </PinkButton>
            </div>
          </div>
          {isLoading && <LoaderComponent />}
          {isError && <p className={css.error}>{errorMessage}</p>}
          <ModalV2Standard
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            width={600}
            icon={<img src={lightningIcon} />}
          >
            <div>
              <p className={css.modalTitle}>
                {intl.formatMessage({
                  id: 'CreatorServicesPage.modalTitle',
                })}
              </p>
              <p className={css.modalSubTitle}>
                {intl.formatMessage({
                  id: 'CreatorServicesPage.modalSubTitle',
                })}
              </p>
            </div>
          </ModalV2Standard>
        </LayoutWrapperMain>
        {/* <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter> */}
      </LayoutSideNavigation>
    </Page>
  );
};

const CreatorServicesPage = injectIntl(CreatorServicesPageComponent);

export default CreatorServicesPage;
