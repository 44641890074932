import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import config from '../../config';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
  isSignupEmailTakenError,
  isTooManyEmailVerificationRequestsError,
} from '../../util/errors';
import {
  Page,
  NamedLink,
  NamedRedirect,
  LinkTabNavHorizontal,
  IconEmailSent,
  InlineTextButton,
  IconClose,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  Modal,
  TermsOfService,
} from '../../components';
import { ConfirmSignupForm, LoginForm, SignupForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import { login, authenticationInProgress, signup, signupWithIdp } from '../../ducks/Auth.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import Cookies from 'js-cookie';

import css from './AuthenticationPage.module.css';
import { EmailIcon, FacebookLogo, GoogleLogo } from './socialLoginLogos';
import { pathByRouteName } from '../../util/routes';
import { SocialLoginButton } from '../../components/Button/Button';
import MainSignupFlow from '../../UpdatedFlow/MainSignupFlow';
import {
  updateProfile,
  uploadImage,
  uploadVideo,
} from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import MainLoginFlow from '../../UpdatedFlow/MainLoginFlow';
import { apiBaseUrl, getMyInfoPersonData } from '../../util/api';
import SingPass from './SingPass';
import {
  requestCreateListing,
  requestUpdateListing,
} from '../EditListingPage/EditListingPage.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { requestAddAvailabilityException } from '../MyCalendarPage/MyCalendarPage.duck';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import Toolbar from '../../components/Toolbar/Toolbar';
import CreatorsTermsOfService from '../../components/CreatorsTermsOfService/CreatorsTermsOfService';

export const AuthenticationPageComponent = props => {
  const {
    authInProgress,
    currentUser,
    intl,
    isAuthenticated,
    location,
    loginError,
    scrollingDisabled,
    signupError,
    confirmError,
    submitLogin,
    submitSignup,
    tab,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    onManageDisableScrolling,
    submitSingupWithIdp,
    history,
  } = props;
  const routes = useRouteConfiguration();
  const [tosModalOpen, setTosModalOpen] = useState(false);
  const [isCreator, setIsCreator] = useState(false);
  const [signupflow, setSignupflow] = useState(
    typeof window !== 'undefined' && (localStorage.getItem('signUpType') || 'learner')
  );
  const [authError, setAuthError] = useState(
    Cookies.get('st-autherror') ? JSON.parse(Cookies.get('st-autherror').replace('j:', '')) : null
  );
  const [authInfo, setAuthInfo] = useState(
    Cookies.get('st-authinfo') ? JSON.parse(Cookies.get('st-authinfo').replace('j:', '')) : null
  );
  const [emailLoginOpen, setEmailLoginOpen] = useState(false);

  const getSignpassData = () => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('code') && searchParams.get('state')) {
      getMyInfoPersonData({
        code: searchParams.get('code'),
        state: searchParams.get('state'),
      }).then(r => {
        console.log('personData', r);
      });
    }
  };

  useEffect(() => {
    setSignupflow(
      typeof window !== 'undefined' &&
        (localStorage.getItem('signUpType') ? localStorage.getItem('signUpType') : '')
    );
    Cookies.remove('st-autherror');
    getSignpassData();
  }, []);

  const isConfirm = tab === 'confirm';
  const isLogin = tab === 'login';
  const from = location.state && location.state.from ? location.state.from : null;
  const user = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!user.id;
  // We only want to show the email verification dialog in the signup
  // tab if the user isn't being redirected somewhere else
  // (i.e. `from` is present). We must also check the `emailVerified`
  // flag only when the current user is fully loaded.
  const showEmailVerification = !isLogin && currentUserLoaded && !user.attributes.emailVerified;
  // Already authenticated, redirect away from auth page
  if (isAuthenticated && from) {
    return <Redirect to={from} />;
  } else if (isAuthenticated && currentUserLoaded && !showEmailVerification) {
    return <NamedRedirect name="CategoriesPage" />;
  }
  const loginErrorMessage = (
    <div className={css.error}>
      <FormattedMessage id="AuthenticationPage.loginFailed" />
    </div>
  );
  const signupErrorMessage = (
    <div className={css.error}>
      {isSignupEmailTakenError(signupError) ? (
        <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
      ) : (
        <FormattedMessage id="AuthenticationPage.signupFailed" />
      )}
    </div>
  );
  const confirmErrorMessage = confirmError ? (
    <div className={css.error}>
      {isSignupEmailTakenError(confirmError) ? (
        <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
      ) : (
        <FormattedMessage id="AuthenticationPage.signupFailed" />
      )}
    </div>
  ) : null;

  // eslint-disable-next-line no-confusing-arrow
  const errorMessage = (error, message) => (error ? message : null);
  const loginOrSignupError = isLogin
    ? errorMessage(loginError, loginErrorMessage)
    : errorMessage(signupError, signupErrorMessage);

  const fromState = { state: from ? { from } : null };

  const tabs = [
    {
      text: (
        <h1 className={css.tab}>
          <FormattedMessage id="AuthenticationPage.signupLinkText" />
        </h1>
      ),
      selected: !isLogin,
      linkProps: {
        name: 'SignupPage',
        to: fromState,
      },
    },
    {
      text: (
        <h1 className={css.tab}>
          <FormattedMessage id="AuthenticationPage.loginLinkText" />
        </h1>
      ),
      selected: isLogin,
      linkProps: {
        name: 'LoginPage',
        to: fromState,
      },
    },
  ];

  const handleSubmitSignup = values => {
    const { fname, lname, ...rest } = values;
    const params = { firstName: fname.trim(), lastName: lname.trim(), ...rest };
    submitSignup(params);
  };

  const handleSubmitConfirm = values => {
    const { idpToken, email, firstName, lastName, idpId } = authInfo;
    const { email: newEmail, firstName: newFirstName, lastName: newLastName, ...rest } = values;
    // Pass email, fistName or lastName to Flex API only if user has edited them
    // sand they can't be fetched directly from idp provider (e.g. Facebook)
    const authParams = {
      ...(newEmail !== email && { email: newEmail }),
      ...(newFirstName !== firstName && { firstName: newFirstName }),
      ...(newLastName !== lastName && { lastName: newLastName }),
    };
    // If the confirm form has any additional values, pass them forward as user's protected data
    const protectedData = !isEmpty(rest) ? { ...rest } : null;
    submitSingupWithIdp({
      idpToken,
      idpId,
      ...authParams,
      ...(!!protectedData && { protectedData }),
    });
  };

  const handleLearner = () => {
    typeof window !== 'undefined' && localStorage.setItem('signUpType', 'learner');
  };

  const handlepro = () => {
    setSignupflow('pro');
    typeof window !== 'undefined' && localStorage.setItem('signUpType', 'pro');
  };

  const getDefaultRoutes = () => {
    const baseUrl = apiBaseUrl();

    // Route where the user should be returned after authentication
    // This is used e.g. with EditListingPage and ListingPage
    const fromParam = from ? `from=${from}` : '';
    // Default route where user is returned after successfull authentication
    const defaultReturn = pathByRouteName('LandingPage', routes);
    const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : '';
    // Route for confirming user data before creating a new user
    const defaultConfirm = pathByRouteName('ConfirmPage', routes);
    const defaultConfirmParam = defaultConfirm ? `&defaultConfirm=${defaultConfirm}` : '';
    return { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam };
  };
  const authWithFacebook = () => {
    const defaultRoutes = getDefaultRoutes();
    const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
    window.location.href = `${baseUrl}/api/auth/facebook?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
  };
  const authWithGoogle = () => {
    const defaultRoutes = getDefaultRoutes();
    const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
    window.location.href = `${baseUrl}/api/auth/google?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
  };
  const idp = authInfo ? authInfo.idpId.replace(/^./, str => str.toUpperCase()) : null;
  // Form for confirming information frm IdP (e.g. Facebook)
  // before new user is created to Flex
  const confirmForm = (
    <div className={css.content}>
      <h1 className={css.signupWithIdpTitle}>
        <FormattedMessage id="AuthenticationPage.confirmSignupWithIdpTitle" values={{ idp }} />
      </h1>
      <p className={css.confirmInfoText}>
        <FormattedMessage id="AuthenticationPage.confirmSignupInfoText" />
      </p>
      {confirmErrorMessage}
      <ConfirmSignupForm
        className={css.form}
        onSubmit={handleSubmitConfirm}
        inProgress={authInProgress}
        onOpenTermsOfService={() => setTosModalOpen(true)}
        authInfo={authInfo}
        idp={idp}
      />
    </div>
  );

  // Social login buttons
  const showFacebookLogin = !!process.env.REACT_APP_FACEBOOK_APP_ID;
  const showGoogleLogin = !!process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const showSocialLogins = showFacebookLogin || showGoogleLogin;

  const facebookButtonText = isLogin ? (
    <FormattedMessage id="AuthenticationPage.loginWithFacebook" />
  ) : (
    <FormattedMessage id="AuthenticationPage.signupWithFacebook" />
  );

  const googleButtonText = isLogin ? (
    <FormattedMessage id="AuthenticationPage.loginWithGoogle" />
  ) : (
    <FormattedMessage id="AuthenticationPage.signupWithGoogle" />
  );

  const socialLoginButtonsMaybe = showSocialLogins ? (
    <div className={css.idpButtons}>
      {showFacebookLogin ? (
        <div className={css.socialButtonWrapper}>
          <SocialLoginButton onClick={() => authWithFacebook()}>
            <span className={css.buttonIcon}>{FacebookLogo}</span>
            {facebookButtonText}
          </SocialLoginButton>
        </div>
      ) : null}
      {!isLogin && (
        <SingPass />
        // <SocialLoginButton onClick={() => _generateCodeChallenge()}>
        //   Retrieve my info
        // </SocialLoginButton>
      )}

      {showGoogleLogin ? (
        <div className={css.socialButtonWrapper}>
          <SocialLoginButton onClick={() => authWithGoogle()}>
            <span className={css.buttonIcon}>{GoogleLogo}</span>
            {googleButtonText}
          </SocialLoginButton>
        </div>
      ) : null}
      <div className={css.socialButtonsOr}>
        <span className={css.socialButtonsOrText}>
          <FormattedMessage id="AuthenticationPage.or" />
        </span>
      </div>
    </div>
  ) : null;

  const emailLoginButton = (
    <div className={css.socialButtonWrapper}>
      <SocialLoginButton onClick={() => setEmailLoginOpen(true)}>
        <span className={css.buttonIcon}>{EmailIcon}</span>
        {isLogin ? 'Sign in with email' : 'Sign up with email'}
      </SocialLoginButton>
    </div>
  );

  const [loginPage, setLoginPage] = useState(false);
  let COMMON_PROPS = { authWithFacebook, authWithGoogle, setLoginPage, tab };
  const formContent = isConfirm ? (
    confirmForm
  ) : (
    <>
      {loginPage || tab === 'login' ? (
        <MainLoginFlow
          {...{ ...COMMON_PROPS, loginError, loginErrorMessage, loginPage, setLoginPage, ...props }}
        />
      ) : (
        <MainSignupFlow
          {...{
            signupError,
            signupErrorMessage,
            setTosModalOpen,
            setIsCreator,
            ...COMMON_PROPS,
            ...props,
          }}
        />
      )}
      {/* <div className={css.content}>
        <LinkTabNavHorizontal className={css.tabs} tabs={tabs} />
        {socialLoginButtonsMaybe}
        {!emailLoginOpen && emailLoginButton}
        {emailLoginOpen && (
          <>
            <div className={css.wrappTab}>
              <NamedLink
                name={'SignupPage'}
                className={`${css.logoLink} ${typeof window !== 'undefined' &&
                  (localStorage.getItem('signUpType') === 'learner' ? css.activeClass : '')} ${css.learner
                  }`}
                onClick={handleLearner}
              >
                Learner
              </NamedLink>
              <NamedLink
                name={'NewListingPage'}
                className={`${css.logoLink} ${typeof window !== 'undefined' &&
                  (localStorage.getItem('signUpType') === 'pro' ? css.activeClass : '')} ${css.pro}`}
                onClick={handlepro}
              >
                Pro
              </NamedLink>
            </div>
            {loginOrSignupError}
            {isLogin ? (
              <LoginForm className={css.form} onSubmit={submitLogin} inProgress={authInProgress} />
            ) : (
              <SignupForm
                className={css.form}
                onSubmit={handleSubmitSignup}
                inProgress={authInProgress}
                onOpenTermsOfService={() => setTosModalOpen(true)}
              />
            )}
          </>
        )}
      </div> */}
    </>
  );

  const name = user.attributes.profile.firstName;
  const email = <span className={css.email}>{user.attributes.email}</span>;

  const resendEmailLink = (
    <InlineTextButton rootClassName={css.modalHelperLink} onClick={onResendVerificationEmail}>
      <FormattedMessage id="AuthenticationPage.resendEmailLinkText" />
    </InlineTextButton>
  );
  const fixEmailLink = (
    <NamedLink className={css.modalHelperLink} name="ContactDetailsPage">
      <FormattedMessage id="AuthenticationPage.fixEmailLinkText" />
    </NamedLink>
  );

  const resendErrorTranslationId = isTooManyEmailVerificationRequestsError(
    sendVerificationEmailError
  )
    ? 'AuthenticationPage.resendFailedTooManyRequests'
    : 'AuthenticationPage.resendFailed';
  const resendErrorMessage = sendVerificationEmailError ? (
    <p className={css.error}>
      <FormattedMessage id={resendErrorTranslationId} />
    </p>
  ) : null;

  const emailVerificationContent = (
    <div className={css.content}>
      {/* <NamedLink
        className={css.verifyClose}
        name={
          typeof window !== 'undefined' &&
          (localStorage.getItem('signUpType') === 'pro'
            ? 'ProUpcomingSessionsPage'
            : 'CategoriesPage')
        }
      >
        <span className={css.closeText}>
          <FormattedMessage id="AuthenticationPage.verifyEmailClose" />
        </span>
        <IconClose rootClassName={css.closeIcon} />
      </NamedLink>
      <IconEmailSent className={css.modalIcon} />
      <h1 className={css.modalTitle}>
        <FormattedMessage id="AuthenticationPage.verifyEmailTitle" values={{ name }} />
      </h1>
      <p className={css.modalMessage}>
        <FormattedMessage id="AuthenticationPage.verifyEmailText" values={{ email }} />
      </p>
      {resendErrorMessage}
      <div className={css.bottomWrapper}>
        <p className={css.modalHelperText}>
          {sendVerificationEmailInProgress ? (
            <FormattedMessage id="AuthenticationPage.sendingEmail" />
          ) : (
            <FormattedMessage id="AuthenticationPage.resendEmail" values={{ resendEmailLink }} />
          )}
        </p>
        <p className={css.modalHelperText}>
          <FormattedMessage id="AuthenticationPage.fixEmail" values={{ fixEmailLink }} />
        </p>
      </div> */}
    </div>
  );

  const siteTitle = config.siteTitle;
  const schemaTitle = isLogin
    ? intl.formatMessage({ id: 'AuthenticationPage.schemaTitleLogin' }, { siteTitle })
    : intl.formatMessage({ id: 'AuthenticationPage.schemaTitleSignup' }, { siteTitle });

  const topbarClasses = classNames({
    [css.hideOnMobile]: showEmailVerification,
  });

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <Toolbar currentPage="LoginPage" showNavButtons={false} showNav={false} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          {/* {socialLoginButtonsMaybe} */}
          <div className={css.root + ' ' + 'dsfdfsdfksjdfjsdlkfjdsfj'}>{formContent}</div>
          <Modal
            id="AuthenticationPage.tos"
            isOpen={tosModalOpen}
            onClose={() => setTosModalOpen(false)}
            usePortal
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.termsWrapper}>
              <h2 className={css.termsHeading}>
                <FormattedMessage id="AuthenticationPage.termsHeading" />
              </h2>
              {isCreator ? <CreatorsTermsOfService /> : <TermsOfService />}
            </div>
          </Modal>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};
AuthenticationPageComponent.defaultProps = {
  currentUser: null,
  loginError: null,
  signupError: null,
  tab: 'signup',
  sendVerificationEmailError: null,
};
const { bool, func, object, oneOf, shape } = PropTypes;
AuthenticationPageComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  loginError: propTypes.error,
  scrollingDisabled: bool.isRequired,
  signupError: propTypes.error,
  submitLogin: func.isRequired,
  submitSignup: func.isRequired,
  tab: oneOf(['login', 'signup']),

  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  // from withRouter
  location: shape({ state: object }).isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};
const mapStateToProps = state => {
  const { isAuthenticated, loginError, signupError, confirmError } = state.Auth;
  const page = state.EditListingPage;
  const {
    currentUser,
    currentUserListing,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  } = state.user;
  const {
    image,
    uploadImageError,
    uploadInProgress,
    updateInProgress,
    updateProfileError,
  } = state.ProfileSettingsPage;
  const getOwnListing = id => {
    const listings = getMarketplaceEntities(state, [{ id, type: 'ownListing' }]);
    return listings.length === 1 ? listings[0] : null;
  };
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
    confirmError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,

    uploadImageError,
    uploadInProgress,
    updateInProgress,
    updateProfileError,
    page,
    image,
    currentUserListing,
    getOwnListing,
  };
};

const mapDispatchToProps = dispatch => ({
  submitLogin: ({ email, password }, callback) => dispatch(login(email, password, callback)),
  submitSignup: (params, callback) => dispatch(signup(params, callback)),
  submitSingupWithIdp: params => dispatch(signupWithIdp(params)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onImageUpload: (data, callback = null) => dispatch(uploadImage(data, callback)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
  onUpdateListing: (tab, values, section) => dispatch(requestUpdateListing(tab, values, section)),
  onCreateListing: (values, callback) => dispatch(requestCreateListing(values, callback)),
  onAddAvailabilityException: params => dispatch(requestAddAvailabilityException(params)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onVideoUpload: (data, callback = null) => dispatch(uploadVideo(data, callback)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AuthenticationPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AuthenticationPageComponent);
export default AuthenticationPage;
