import React from 'react';
import css from './index.module.css';

import campaignMarketplace from '../../../../../assets/become-pro/campaign-marketplace.webp';
import arrow_right from '../../../../../assets/become-pro/arrow-right.svg';
import { useHistory } from 'react-router-dom';
import { useSetAtom } from 'jotai';
import { openCreatorDemoDialogAtom } from '../../../../../atoms/creatordemo';

const CampaignMarketplace = () => {
  const history = useHistory();
  const setOpenModal = useSetAtom(openCreatorDemoDialogAtom);

  return (
    <div className={css.wrapper}>
      <div className={css.content}>
        <div className={css.title}>Campaign Marketplace</div>
        <div className={css.description}>
          Connect with brands who are eager to collaborate with talented creators like you. From
          curated experiences to exclusive discounts, apply to opportunities that you resonate with.
        </div>
        <div className={css.btnContainer}>
          <button className={css.btn_join} onClick={() => history.push('/login')}>
            Join now
            <img src={arrow_right} alt="right arrow" />
          </button>
          <button className={css.btnWhiteOutline} onClick={() => setOpenModal(true)}>
            Book a demo
          </button>
        </div>
      </div>
      <div className={css.imageContainer}>
        <img className={css.image} src={campaignMarketplace} alt="campaign-marketplace" />
      </div>
    </div>
  );
};

export default CampaignMarketplace;
