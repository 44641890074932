import React from 'react';

import css from './index.module.css';

import Chip from '../../../../../components/Chip/Chip';

import jobFeaturePreview from '../../../../../assets/creator-page/jobFeaturePreview.png'
import fireIcon from '../../../../../assets/icons/fire.svg';
import arrow_right from '../../../../../assets/become-pro/arrow-right.svg';
import { useSetAtom } from 'jotai';
import { openCreatorDemoDialogAtom } from '../../../../../atoms/creatordemo';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const JobBoard = () => {
  const history = useHistory();
  const setOpenModal = useSetAtom(openCreatorDemoDialogAtom);

  return (
    <div className={css.wrapper}>
      <div className={css.content}>
        <Chip
          borderColor="#F3F4E4"
          backgroundColor="#F3F4E4"
          textColor="#292929"
          padding="4px 12px 4px 10px"
          mobilePadding="4px 10px 4px 8px"
        >
          <img src={fireIcon} />
          Coming soon
        </Chip>
        <div className={css.title}>Job Board</div>
        <div className={css.description}>
          Discover freelance, contract, or part-time gigs with leading brands. Showcase your skills, grow your content portfolio, and get hired for exciting content creation opportunities.
        </div>
        <div className={css.btnContainer}>
          <button className={css.btn_join} onClick={() => history.push('/login')}>
            Join now
            <img src={arrow_right} alt="right arrow" />
          </button>
          <button className={css.btnWhiteOutline} onClick={() => setOpenModal(true)}>
            Book a demo
          </button>
        </div>
      </div>
      <div className={css.imageContainer}>
        <img className={css.image} src={jobFeaturePreview} alt="job-feature" />
      </div>
    </div>
  );
};

export default JobBoard;
