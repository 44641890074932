import Button from "../../Button/Button";
import arrowLeft from '../../../assets/icons/arrow-left-bold.svg';
import arrowRight from '../../../assets/icons/arrow-right-bold.svg';
import css from './ArrowPagination.module.css';

export const ArrowPagination = ({
  currentPage,
  totalPages,
  onPageChange,
  maxPageButtons = 5,
}) => {
  const pages = [];

  // Determine start and end page based on the current page and maxPageButtons
  let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
  let endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

  if (endPage - startPage + 1 < maxPageButtons) {
    startPage = Math.max(endPage - maxPageButtons + 1, 1);
  }

  // Add first page, ellipsis if necessary, visible page range, and last page
  if (startPage > 1) pages.push(1);
  if (startPage > 2) pages.push('...');
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }
  if (endPage < totalPages - 1) pages.push('...');
  if (endPage < totalPages) pages.push(totalPages);

  return (
    <div className={css.pagination}>
      <Button
        className={css.paginationPrev}
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <img src={arrowLeft} alt="Previous" />
      </Button>
      <div className={css.paginationNumberWrapper}>
        {pages.map((page, index) => (
          <button
            key={index}
            onClick={() => typeof page === 'number' && onPageChange(page)}
            className={
              page === currentPage ? css.paginationCurrentPage : css.paginationPages
            }
            disabled={page === '...'}
          >
            {page}
          </button>
        ))}
      </div>
      <Button
        className={css.paginationNext}
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <img src={arrowRight} alt="Next" />
      </Button>
    </div>
  );
};
