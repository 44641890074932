import React from 'react';
import { InputFieldComp, SelectFieldComp } from '../../components/CommonComp/FieldComponent';
import { isUploadImageOverLimitError } from '../../../util/errors';
import _ from 'lodash';
import classNames from 'classnames';
import ProStepperCss from '../../styles/ProStepper.style';
import getCountryCodes from '../../../translations/countryCodes';
import config from '../../../config';

const CLIENT_INTERESTS = [
  { id: '1', title: 'Lifestyle', value: 'Lifestyle' },
  { id: '2', title: 'Fashion', value: 'Fashion' },
  { id: '3', title: 'Beauty', value: 'Beauty' },
  { id: '4', title: 'Health & Wellness', value: 'Health & Wellness' },
  { id: '5', title: 'Food & Beverage', value: 'Food & Beverage' },
  { id: '6', title: 'Travel', value: 'Travel' },
  { id: '7', title: 'Other', value: 'Other' },
];

function LearnerFirstStep(props) {
  const countryCodes = getCountryCodes(config.locale);
  const {
    imageUpload,
    handleClick,
    inputRef,
    _handleImageChange,
    uploadImageError,
    errors,
    setFieldValue,
  } = props;

  let error = null;
  if (isUploadImageOverLimitError(uploadImageError)) {
    error = (
      <div className={css.error}>
        <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
      </div>
    );
  } else if (uploadImageError) {
    error = (
      <div className={css.error}>
        <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
      </div>
    );
  }

  const { selectedButton, _handleInterested } = props;

  return (
    <div className="proSecond_main">
      <div className="fieldWithLabel uploadSection">
        <div className="imagePreview">
          <img src={imageUpload?.preview || require('../../assest/profile-pic.png')} alt="dummy" />
        </div>
        <div className="file_selection">
          <input
            type="file"
            ref={inputRef}
            accept={'image/*'}
            id="upload-button"
            style={{ display: 'none' }}
            onChange={_handleImageChange}
          />
          <button type="button" onClick={handleClick} className="uploadPhotoButton">
            Upload Company Logo
          </button>
        </div>
      </div>
      {(error || imageUpload?.errMsg) && (
        <div className="error">{error || imageUpload?.errMsg}</div>
      )}
      <div className="fieldWithLabel">
        <label htmlFor="">About the company</label>
        <InputFieldComp
          type="textarea"
          name="bio"
          placeholder="Description"
          error={errors.bio}
        />
      </div>
      <div className="fieldWithLabel">
        <label htmlFor="">LinkedIn</label>
        <InputFieldComp
          name="linkedin"
          placeholder="Enter your linked in"
          error={errors.linkedin}
        />
      </div>
      <div className="fieldWithLabel">
        <label htmlFor="">Instagram</label>
        <InputFieldComp
          name="instagram"
          placeholder="Enter your linked in"
          error={errors.instagram}
        />
      </div>
      <div className="fieldWithLabel">
        <label htmlFor="">TikTok</label>
        <InputFieldComp
          name="tiktok"
          placeholder="Enter your linked in"
          error={errors.tiktok}
        />
      </div>
      <div className="fieldWithLabel">
        <label htmlFor="">Contact Person</label>
        <InputFieldComp
          name="displayName"
          placeholder="Enter your full name here"
          error={errors.displayName}
        />
      </div>
      <div className="fieldWithLabel">
        <label htmlFor="">Role</label>
        <SelectFieldComp
          name="role"
          options={_.map(['Marketing Team', 'Product Team', 'Founder Team', 'Other'], r => ({
            label: r,
            value: r,
          }))}
          error={errors?.role}
        />
      </div>
      <div className="fieldWithLabel">
        <label htmlFor="">How did you hear about Collabreate</label>
        <SelectFieldComp
          name="hearFrom"
          options={_.map(
            ['facebook', 'linkedin ', 'instagram', 'twitter', 'tiktok', 'others'],
            r => ({
              label: _.capitalize(r),
              value: r,
            })
          )}
          error={errors?.hearFrom}
        />
      </div>
    </div>
  );
}

export default LearnerFirstStep;
