import React from 'react';
import { string, arrayOf, bool, func, number } from 'prop-types';
import dropWhile from 'lodash/dropWhile';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { formatDateWithProximity, formatStringDate } from '../../../util/dates';
import { propTypes } from '../../../util/types';
import {
  getProcess,
  getUserTxRole,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_OPERATOR,
  TX_TRANSITION_ACTOR_SYSTEM,
} from '../../../transactions/transaction';

import {
  Avatar,
  Button,
  ExternalLink,
  InlineTextButton,
  ReviewRating,
  UserDisplayName,
} from '../../../components';

import { stateDataShape } from '../TransactionPage.stateData';

import css from './ActivityFeed.module.css';
import Input from './Input';
import { TRANSITION_CONFIRM_PAYMENT } from '../../../util/transaction';
import { MessageContent } from '../../../components/MessageContent/MessageContent';

const Message = props => {
  const {
    message,
    formattedDate,
    onOpenDisputeModal,
    acceptOrder,
    ownRole,
    sendRevisionMessage,
  } = props;
  return (
    <div className={css.message}>
      {/* <Avatar className={css.avatar} user={message.sender} /> */}
      <div>
        <p className={css.messageContent}>
          {message.attributes.content.startsWith('https://res.cloudinary.com/oneprolead/video/') &&
          ownRole === TX_TRANSITION_ACTOR_CUSTOMER ? (
            <div>
              <video width={320} src={message.attributes.content} controls />
              <div className={css.orderMeaasge}>
                Your order is completed! Once the project is accepted, payment will be processed to
                the creator.
              </div>
              <div className={css.buttonsContainer}>
                <Button className={css.orderControlButton} onClick={onOpenDisputeModal}>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="heroicons-outline/question-mark-circle">
                      <path
                        id="Vector"
                        d="M7.40918 5.63913C8.28786 4.87029 9.71248 4.87029 10.5912 5.63913C11.4698 6.40798 11.4698 7.65452 10.5912 8.42337C10.4382 8.55718 10.2688 8.66771 10.0885 8.75495C9.52923 9.02561 9.00017 9.50368 9.00017 10.125V10.6875M15.75 9C15.75 12.7279 12.7279 15.75 9 15.75C5.27208 15.75 2.25 12.7279 2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C12.7279 2.25 15.75 5.27208 15.75 9ZM9 12.9375H9.00563V12.9431H9V12.9375Z"
                        stroke="#100C20"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                </Button>{' '}
                <Button
                  className={css.orderControlButton}
                  onClick={() => sendRevisionMessage('Customer requested to revise the order.')}
                >
                  Revision
                </Button>
                <Button className={css.orderControlButton} onClick={acceptOrder}>
                  Accept
                </Button>
              </div>
            </div>
          ) : (
            <MessageContent message={message.attributes.content} />
          )}
        </p>
        <p className={css.messageDate}>{formattedDate}</p>
      </div>
    </div>
  );
};
// str1.startsWith('Sat')
Message.propTypes = {
  message: propTypes.message.isRequired,
  formattedDate: string.isRequired,
};

const OwnMessage = props => {
  const { message, formattedDate } = props;
  const messageContent = message.attributes.content;

  return (
    <div className={css.ownMessage}>
      <div className={css.ownMessageContentWrapper}>
        <p className={css.ownMessageContent}>
          <MessageContent message={messageContent} />
        </p>
      </div>
      <p className={css.ownMessageDate}>{formattedDate}</p>
    </div>
  );
};

OwnMessage.propTypes = {
  message: propTypes.message.isRequired,
  formattedDate: string.isRequired,
};

const Review = props => {
  const { content, rating } = props;
  return (
    <div>
      <p className={css.reviewContent}>{content}</p>
      {rating ? (
        <ReviewRating
          reviewStarClassName={css.reviewStar}
          className={css.reviewStars}
          rating={rating}
        />
      ) : null}
    </div>
  );
};

Review.propTypes = {
  content: string.isRequired,
  rating: number.isRequired,
};

const TransitionMessage = props => {
  const {
    transition,
    nextState,
    stateData,
    deliveryMethod,
    listingTitle,
    ownRole,
    otherUsersName,
    onOpenReviewModal,
    intl,
  } = props;
  const { processName, processState, showReviewAsFirstLink, showReviewAsSecondLink } = stateData;
  const stateStatus = nextState === processState ? 'current' : 'past';

  // actor: 'you', 'system', 'operator', or display name of the other party
  const actor =
    transition.by === ownRole
      ? 'you'
      : [TX_TRANSITION_ACTOR_SYSTEM, TX_TRANSITION_ACTOR_OPERATOR].includes(transition.by)
      ? transition.by
      : otherUsersName;

  const reviewLink = showReviewAsFirstLink ? (
    <InlineTextButton onClick={onOpenReviewModal}>
      <FormattedMessage id="TransactionPage.ActivityFeed.reviewLink" values={{ otherUsersName }} />
    </InlineTextButton>
  ) : showReviewAsSecondLink ? (
    <InlineTextButton onClick={onOpenReviewModal}>
      <FormattedMessage
        id="TransactionPage.ActivityFeed.reviewAsSecondLink"
        values={{ otherUsersName }}
      />
    </InlineTextButton>
  ) : null;

  // ActivityFeed messages are tied to transaction process and transitions.
  // However, in practice, transitions leading to same state have had the same message.
  const message = intl.formatMessage(
    { id: `TransactionPage.ActivityFeed.${processName}.${nextState}` },
    { actor, otherUsersName, listingTitle, reviewLink, deliveryMethod, stateStatus }
  );

  return message;
};

const Transition = props => {
  const { transitionMessageComponent, formattedDate, reviewComponent } = props;
  return (
    <div className={css.transition}>
      <div className={css.transitionContainer}>
        <p className={css.transitionContent}>{transitionMessageComponent}</p>
        <p className={css.transitionDate}>{formattedDate}</p>
        {reviewComponent}
      </div>
    </div>
  );
};

Transition.propTypes = {
  formattedDate: string,
};

const reviewByAuthorId = (transaction, userId) => {
  return transaction.reviews.filter(
    r => !r.attributes.deleted && r.author.id.uuid === userId.uuid
  )[0];
};

const ReviewComponentMaybe = props => {
  const { showReviews, isRelevantTransition, reviewEntity, intl } = props;
  if (showReviews && isRelevantTransition) {
    const deletedReviewContent = intl.formatMessage({
      id: 'TransactionPage.ActivityFeed.deletedReviewContent',
    });
    const content = reviewEntity?.attributes?.deleted
      ? deletedReviewContent
      : reviewEntity?.attributes?.content;
    const rating = reviewEntity?.attributes?.rating;
    const ratingMaybe = rating ? { rating } : {};
    return <Review content={content} {...ratingMaybe} />;
  }
  return null;
};

const isMessage = item => item && item.type === 'message';

// Compare function for sorting an array containing messages and transitions
const compareItems = (a, b) => {
  const itemDate = item => (isMessage(item) ? item.attributes.createdAt : item.createdAt);
  return itemDate(a) - itemDate(b);
};

const organizedItems = (messages, transitions, hideOldTransitions) => {
  const items = messages.concat(transitions).sort(compareItems);
  if (hideOldTransitions) {
    // Hide transitions that happened before the oldest message. Since
    // we have older items (messages) that we are not showing, seeing
    // old transitions would be confusing.
    return dropWhile(items, i => !isMessage(i));
  } else {
    return items;
  }
};

export const ActivityFeedComponent = props => {
  const {
    rootClassName,
    className,
    messages,
    transaction,
    stateData,
    currentUser,
    hasOlderMessages,
    fetchMessagesInProgress,
    onOpenReviewModal,
    onShowOlderMessages,
    intl,
    metadata,
    handleRejectDispute,
    onOpenDisputeModal,
    onSendMessage,
    config,
    campaign,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const processName = stateData.processName;

  const sendRevisionMessage = message => {
    onSendMessage(transaction.id, message, config)
      .then(messageId => {
        form.reset();
        if (this.state.showFileInput) {
          this.setState({ clearFileInput: true });
          this.setState({ showFileInput: false });
        }

        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  };

  // If stateData doesn't have processName, full tx data has not been fetched.
  if (!processName) {
    return null;
  }
  const process = getProcess(processName);
  const transitions = transaction?.attributes?.transitions || [];
  const relevantTransitions = transitions.filter(t =>
    process.isRelevantPastTransition(t.transition)
  );
  const todayString = intl.formatMessage({ id: 'TransactionPage.ActivityFeed.today' });
  const ownRole = getUserTxRole(currentUser.id, transaction);
  // combine messages and transaction transitions
  const hideOldTransitions = hasOlderMessages || fetchMessagesInProgress;
  const items = organizedItems(messages, relevantTransitions, hideOldTransitions);

  const messageListItem = message => {
    const formattedDate = formatDateWithProximity(message.attributes.createdAt, intl, todayString);
    const isOwnMessage = currentUser?.id && message?.sender?.id?.uuid === currentUser.id?.uuid;

    const messageComponent = isOwnMessage ? (
      <>
        <OwnMessage message={message} formattedDate={formattedDate} />
        {/* <Button onClick={handleRejectDispute}>Reject dispute</Button>
        <Button onClick={stateData?.primaryButtonProps?.onAction}>
          {stateData?.primaryButtonProps?.buttonText}
        </Button>
        <Button onClick={onOpenDisputeModal}>Open dispute</Button> */}
      </>
    ) : (
      <>
        <Message
          message={message}
          formattedDate={formattedDate}
          onOpenDisputeModal={onOpenDisputeModal}
          acceptOrder={stateData?.primaryButtonProps?.onAction}
          ownRole={ownRole}
          sendRevisionMessage={sendRevisionMessage}
        />
        {/* <Button onClick={handleRejectDispute}>Reject dispute</Button> */}
      </>
    );

    return (
      <li id={`msg-${message.id.uuid}`} key={message.id.uuid} className={css.messageItem}>
        {messageComponent}
      </li>
    );
  };
  const listingTitle = transaction.listing.attributes.deleted
    ? intl.formatMessage({ id: 'TransactionPage.ActivityFeed.deletedListing' })
    : transaction.listing.attributes.title;
  const transitionListItem = transition => {
    const formattedDate = formatDateWithProximity(transition.createdAt, intl, todayString);
    const { customer, provider, listing } = transaction || {};

    // Initially transition component is empty;
    let transitionComponent = <Transition />;

    if (currentUser?.id && customer?.id && provider?.id && listing?.id) {
      const transitionName = transition.transition;
      const nextState = process.getStateAfterTransition(transition.transition);
      const isCustomerReview = process.isCustomerReview(transitionName);
      const isProviderRieview = process.isProviderReview(transitionName);
      const reviewEntity = isCustomerReview
        ? reviewByAuthorId(transaction, customer.id)
        : isProviderRieview
        ? reviewByAuthorId(transaction, provider.id)
        : null;

      const otherUser = ownRole === TX_TRANSITION_ACTOR_PROVIDER ? customer : provider;

      transitionComponent = (
        <Transition
          formattedDate={formattedDate}
          transitionMessageComponent={
            <TransitionMessage
              transition={transition}
              nextState={nextState}
              stateData={stateData}
              deliveryMethod={transaction.attributes?.protectedData?.deliveryMethod}
              listingTitle={listingTitle}
              ownRole={ownRole}
              otherUsersName={<UserDisplayName user={otherUser} intl={intl} />}
              onOpenReviewModal={onOpenReviewModal}
              intl={intl}
            />
          }
          reviewComponent={
            <ReviewComponentMaybe
              showReviews={stateData.showReviews}
              isRelevantTransition={isCustomerReview || isProviderRieview}
              reviewEntity={reviewEntity}
              intl={intl}
            />
          }
        />
      );
    }

    return (
      <div className={css.transitionItemContainer}>
        {campaign ? (
          <li
            key={transition.transition}
            className={classNames(
              css.transitionItem,
              ownRole === TX_TRANSITION_ACTOR_CUSTOMER ? css.customerInitialMessage : null
            )}
          >
            {transitionComponent}
            {
              <div>
                <div className={css.subtitle}>Title</div>
                <div className={css.feedComponentContent}>{listingTitle}</div>
                <div className={css.subtitle}>Description</div>
                <div className={css.feedComponentContent}>{campaign?.offerDescription}</div>
                <div className={css.subtitle}>Deadline</div>
                <div className={css.feedComponentContent}>
                  {formatStringDate(campaign?.endDate)}
                </div>
                <div>
                  <div className={css.subtitle}>Inspiration links</div>
                  {campaign?.deliverables?.map(d => (
                    <div key={d.id}>
                      {d?.references ||
                        d?.references?.map(r => (
                          <ExternalLink href={r} key={r} className={css.link}>
                            {r}
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="right_icon">
                                <path
                                  id="Union"
                                  d="M12.4849 6.04171L4.26318 14.2634L5.73632 15.7365L13.9581 7.51474V14.1667H16.0414V3.95837H5.83311V6.04171H12.4849Z"
                                  fill="#494656"
                                />
                              </g>
                            </svg>
                          </ExternalLink>
                        ))}
                    </div>
                  ))}
                </div>
              </div>
            }
            {/* <div className="file_selection">
            {videoIdsTOUpload?.length > 0 ? <p> {imagesIdsTOUpload?.length} file uploaded</p> : ''}
            <input
              type="file"
              ref={listingInputRef}
              id="upload-button"
              style={{ display: 'none' }}
              onChange={_handleListingImageChange}
            />
            <button type="button" onClick={handleClickListing} className="uploadPhotosButton">
              Upload
            </button>
          </div>
          {uploadInProgress && <span>Uploading...</span>}
          {uploadImageFailed} */}
            {/* <input
            type="file"
            onChange={e => {
              console.log(e.target.value);
            }}
          /> */}
          </li>
        ) : (
          <li
            key={transition.transition}
            className={classNames(
              css.transitionItem,
              ownRole === TX_TRANSITION_ACTOR_CUSTOMER ? css.customerInitialMessage : null
            )}
          >
            {transitionComponent}
            {
              <div>
                <div className={css.subtitle}>Title</div>
                <div className={css.feedComponentContent}>{listingTitle}</div>
                <div className={css.subtitle}>Brief name</div>
                <div className={css.feedComponentContent}>{metadata.briefName}</div>
                <div className={css.subtitle}>Description</div>
                <div className={css.feedComponentContent}>{metadata.description}</div>
                <div className={css.subtitle}>Reservation/PR arrival</div>
                <div className={css.feedComponentContent}>{metadata.reservationDate}</div>
                <div className={css.subtitle}>Deadline</div>
                <div className={css.feedComponentContent}>{metadata.deliveryDate}</div>
                <div>
                  <div className={css.subtitle}>Inspiration links</div>
                  {metadata?.links?.map(link => (
                    <div key={link}>
                      <ExternalLink href={link} className={css.link}>
                        {link}
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="right_icon">
                            <path
                              id="Union"
                              d="M12.4849 6.04171L4.26318 14.2634L5.73632 15.7365L13.9581 7.51474V14.1667H16.0414V3.95837H5.83311V6.04171H12.4849Z"
                              fill="#494656"
                            />
                          </g>
                        </svg>
                      </ExternalLink>
                    </div>
                  ))}
                </div>
              </div>
            }
            {/* <div className="file_selection">
            {videoIdsTOUpload?.length > 0 ? <p> {imagesIdsTOUpload?.length} file uploaded</p> : ''}
            <input
              type="file"
              ref={listingInputRef}
              id="upload-button"
              style={{ display: 'none' }}
              onChange={_handleListingImageChange}
            />
            <button type="button" onClick={handleClickListing} className="uploadPhotosButton">
              Upload
            </button>
          </div>
          {uploadInProgress && <span>Uploading...</span>}
          {uploadImageFailed} */}
            {/* <input
            type="file"
            onChange={e => {
              console.log(e.target.value);
            }}
          /> */}
          </li>
        )}
      </div>
    );
  };

  return (
    <ul className={classes}>
      {hasOlderMessages ? (
        <li className={css.showOlderWrapper} key="show-older-messages">
          <InlineTextButton className={css.showOlderButton} onClick={onShowOlderMessages}>
            <FormattedMessage id="TransactionPage.ActivityFeed.showOlderMessages" />
          </InlineTextButton>
        </li>
      ) : null}
      {items?.map(item => {
        if (isMessage(item)) {
          return messageListItem(item);
        } else {
          return transitionListItem(item);
        }
      })}
    </ul>
  );
};

ActivityFeedComponent.defaultProps = {
  rootClassName: null,
  className: null,
  stateData: {},
};

ActivityFeedComponent.propTypes = {
  rootClassName: string,
  className: string,

  messages: arrayOf(propTypes.message),
  transaction: propTypes.transaction,
  stateData: stateDataShape,
  currentUser: propTypes.currentUser,
  hasOlderMessages: bool.isRequired,
  fetchMessagesInProgress: bool.isRequired,
  onOpenReviewModal: func.isRequired,
  onShowOlderMessages: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ActivityFeed = injectIntl(ActivityFeedComponent);

export default ActivityFeed;
