import css from './MobileToolbar.module.css';
import Button, { GrayButton, PinkButton, SoftBlackButton } from '../../Button/Button';
import Logo from '../../Logo/Logo';
import NamedLink from '../../NamedLink/NamedLink';
import MenuIcon from '../../Topbar/MenuIcon';
import Modal from '../../Modal/Modal';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { manageDisableScrolling } from '../../../ducks/UI.duck';
import searchImage from '../../../assets/search.svg';
import { Field, Form } from 'react-final-form';
import { createResourceLocatorString } from '../../../util/routes';
import { useHistory } from 'react-router-dom';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { bool, func, number, string } from 'prop-types';
import { propTypes } from '../../../util/types';
import TopbarMobileMenu from '../../TopbarMobileMenu/TopbarMobileMenu';

const MobileToolbar = ({ isAuthenticated, currentUser, isDark, showNavButtons = true, showNav = true, ...props }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const routeConfig = useRouteConfiguration();
  const isCreator = currentUser?.attributes?.profile?.protectedData?.isCreator;

  const handleManageDisableScrolling = (componentId, disableScrolling) => {
    dispatch(manageDisableScrolling(componentId, disableScrolling));
  };

  const handleSearch = v => {
    const searchParams = {
      keywords: v.search,
    };
    history.push(createResourceLocatorString('SearchPage', routeConfig, {}, searchParams));
  };

  return (
    <>
      <div className={`${css.container} ${isDark ? css.bgDark : css.bgWhite}`}>
        <div className={css.topbarLeftContainer}>
          <Button rootClassName={css.menu} onClick={() => setOpen(true)}>
            <MenuIcon className={`${css.menuIcon} ${isDark ? css.textWhite : css.textDark}`} />
          </Button>
          <NamedLink className={css.home} name="LandingPage">
            <Logo format="mobile" className={css.logo} isDark={isDark} />
          </NamedLink>
        </div>
        {!isAuthenticated ? (
          <div className={css.mobileTopbarButtonContainer}>
            <NamedLink name="LoginPage">
              <div className={css.btnLogin}>Log in</div>
            </NamedLink>
          </div>
        ) : isCreator ? (
          <NamedLink
            name="ProProfilePage"
            params={{ id: currentUser?.id?.uuid || '0' }}
            className={css.profileLink}
          >
            <div className={css.btnProfile}>Go to Profile</div>
          </NamedLink>
        ) : null}
      </div>
      {isAuthenticated ? (
        <Modal
          id="TopbarMobileMenu"
          isOpen={open}
          onClose={() => setOpen(false)}
          usePortal
          onManageDisableScrolling={handleManageDisableScrolling}
          isSidebar
        >
          <TopbarMobileMenu
            isAuthenticated={isAuthenticated}
            currentUserHasListings={props.currentUserHasListings}
            currentUserListing={props.currentUserListing}
            currentUserListingFetched={props.currentUserListingFetched}
            currentUser={props.currentUser}
            onLogout={props.onLogout}
            notificationCount={props.notificationCount}
            currentPage={props.currentPage}
          />
        </Modal>
      ) : (
        <Modal
          id="modalMenu"
          containerClassName={css.modalContainer}
          usePortal
          isOpen={open}
          onClose={() => setOpen(false)}
          onManageDisableScrolling={handleManageDisableScrolling}
          isSidebar
        >
          <div className={`${css.root} ${isDark ? css.textWhite : css.textDark} ${isDark ? css.bgDark : css.bgWhite}`}>
            <nav className={css.contentBasic}>
              {showNav && (
                <>
                  <NamedLink className={`${isDark ? css.textWhite : css.textDarkLink} ${css.navigationLink}`} name="BecomeProPage">
                    Creators
                  </NamedLink>
                  <NamedLink className={`${isDark ? css.textWhite : css.textDarkLink} ${css.navigationLink}`} name="LandingPage">
                    Brands
                  </NamedLink>
                </>
              )}
              {showNavButtons && (
                <>
                  <NamedLink className={`${isDark ? css.textWhite : css.textDarkLink} ${css.navigationLink}`} name="LoginPage">
                    Log In
                  </NamedLink>
                  <NamedLink className={`${isDark ? css.textWhite : css.textDarkLink} ${css.navigationLink}`} name="SignupPage">
                    Sign Up
                  </NamedLink>
                </>
              )}
            </nav>
          </div>
        </Modal>
      )}
    </>
  );
};

MobileToolbar.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  currentPage: null,
  currentUserListing: null,
  currentUserListingFetched: false,
  isDark: false,
};
MobileToolbar.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  isDark: bool,
};

export default MobileToolbar;
