import fileIcon from '../../assets/icons/file.svg';
import downloadIcon from '../../assets/icons/arrow-down.svg';
import css from './MessageContent.module.css';
import Linkify from 'linkify-react';

export const MessageContent = ({
  message,
}) => {
  if (!message) {
    return null;
  }

  const showDownloadButton = message.startsWith('https://res.cloudinary.com/oneprolead/raw/') || message.startsWith('https://res.cloudinary.com/oneprolead/image/');

  return (
    <div className={css.positionRelative}>
      {message.startsWith('https://res.cloudinary.com/oneprolead/video/') ? (
        <video width={320} src={message} controls />
      ) :
      message.startsWith('https://res.cloudinary.com/oneprolead/image/') ? (
        <img src={message} alt="image" className={css.imageMessage} />
      ) :
      message.startsWith('https://res.cloudinary.com/oneprolead/raw/') ? (
        <div className={css.previewFile}>
          <img src={fileIcon} className={css.fileIcon} />
        </div>
      ) : (
        <Linkify>{message}</Linkify>
      )}
      {showDownloadButton && (
        <div className={css.downloadIconContainer}>
          <a href={message} target='_blank'>
            <img src={downloadIcon} className={css.downloadIcon} />
          </a>
        </div>
      )}
    </div>
  )
}
