import React, { useEffect, useState } from 'react';
import {
  Page,
} from '../../../components';
import css from './ApplyAmbassadorPage.module.css';
import { Button } from '../../../components/New/Button/Button';
import axiosInstance from '../../../axios';
import { TextField } from '../../../components/New/TextField/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import Loading from '../../../components/Loading/Loading';
import logoShort from '../../../assets/logo/logoShort.svg';
import logoLetters from '../../../assets/logo/logoLetters.png';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

export default function ApplyAmbassadorPage () {
  const location = useLocation();
  const currentPath = location.pathname;
  //remove /ambassador/ from the path
  const slug = currentPath.replace('/ambassador/', '');

  const [ambassador, setAmbassador] = useState(null);
  const [client, setClient] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [status, setStatus] = useState(null);

  const fetchAmbassador = async () => {
    try {
      const response = await axiosInstance.get(`/client/ambassador/find/slug?slug=${slug}`);
      const { data } = response.data;
      setAmbassador(data);

      if (data.status === 'inactive') {
        setStatus('inactive');
      } else {
        setStatus('register');
      }

      const responseClient = await axiosInstance.get(`/user/detail/${data.userId}`);
      const { data: dataClient } = responseClient.data;
      setClient(dataClient);
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  const applyToProgramme = async () => {
    try {
      setLoadingSubmit(true);
      const {
        firstName,
        lastName,
        email,
        instagram,
        tiktok
      } = formik.values;

      const response = await axiosInstance.post('/apply/ambassador', {
        ambassadorId: ambassador.id,
        firstName,
        lastName,
        email,
        instagram,
        tiktok
      });

      const { error, data, message } = response.data;
      if (error) {
        toast.error(message);
        return;
      }

      setStatus('applied');
      toast.success('Application submitted successfully');
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingSubmit(false);
    }
  }

  useEffect(() => {
    fetchAmbassador();
  }, []);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      instagram: '',
      tiktok: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email').required('Required'),
      instagram: Yup.string().required('Required'),
      tiktok: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      await applyToProgramme();
    }
  })

  return (
    <>
      <Page
        title={"Ambassador"}
        scrollingDisabled={false}
      >
        {status === 'register' && (
          <div className={css.root}>
            <div className={css.left}>
              <img src={ambassador?.image} className={css.bannerImage} />
            </div>
            <div className={css.right}>
              <img src={client?.avatar} className={css.logoImage} />
              <div className={css.contentContainer}>
                <div className={css.title}>{ambassador?.title}</div>
                <div className={css.description}>{ambassador?.description}</div>
                <div className={css.sectionText}>Personal Information</div>
                <div className={css.formContainer}>
                  <div>
                    <div className={css.label}>First Name</div>
                    <TextField name={'firstName'} placeholder={'John'} value={formik.values.firstName} onChange={formik.handleChange} errorMessage={formik.errors.firstName}/>
                  </div>
                  <div>
                    <div className={css.label}>Last Name</div>
                    <TextField name={'lastName'} placeholder={'John'} value={formik.values.lastName} onChange={formik.handleChange} errorMessage={formik.errors.lastName}/>
                  </div>
                  <div className={css.gridItem2}>
                    <div className={css.label}>Email Address</div>
                    <TextField name={'email'} placeholder={'john@gmail.com'} value={formik.values.email} onChange={formik.handleChange} errorMessage={formik.errors.email}/>
                  </div>
                </div>
                <div className={css.sectionText}>Social Media Links</div>
                <div className={css.formContainer}>
                  <div className={css.gridItem2}>
                    <div className={css.label}>Instagram</div>
                    <TextField name={'instagram'} placeholder={'instagram'} value={formik.values.instagram} onChange={formik.handleChange} errorMessage={formik.errors.instagram}/>
                  </div>
                  <div className={css.gridItem2}>
                    <div className={css.label}>TikTok</div>
                    <TextField name={'tiktok'} placeholder={'tiktok'} value={formik.values.tiktok} onChange={formik.handleChange} errorMessage={formik.errors.tiktok}/>
                  </div>
                </div>
              </div>
              <div className={css.btnContainer}>
                <div>
                  <Button color='black' onClick={formik.handleSubmit} disabled={loadingSubmit}>
                    <div className={css.btnText}>
                      Sign Me Up!
                      {loadingSubmit && (
                        <Loading />
                      )}
                    </div>
                  </Button>
                </div>
              </div>
              <div className={css.poweredContainer}>
                <a href="https://www.collabreate.co/" className={css.link}>
                  <div className={css.poweredText}>
                    Powered by
                    <div className={css.logoIconContainer}>
                      <img src={logoShort} alt="logo" className={css.logoIcon} />
                      <img
                        src={logoLetters}
                        alt="logoName"
                        className={css.logoNameIcon}
                      />
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        )}
        {status === 'applied' && (
          <div className={css.rootApplied}>
            <div className={css.appliedContainer}>
              <div className={css.textCenter}>
                <div className={css.title}>You're All Set!</div>
                <div className={css.appliedSubtitle}>
                  Thank you for applying to our Ambassador Program! Your application is now under review, and we're excited to have you on our radar.<br/>
                  Keep an eye on your inbox—we'll be in touch soon about upcoming campaigns and collaboration opportunities.
                  <br />
                  <br />
                  If we need more details, we'll reach out directly using the contact information you provided.<br/>
                  Stay tuned and get ready to make an impact with us!
                </div>
              </div>
            </div>
          </div>
        )}
        {status === 'inactive' && (
          <div className={css.rootApplied}>
            <div className={css.appliedContainer}>
              <div className={css.textCenter}>
                <div className={css.title}>This Ambassador Program is No Longer Active</div>
                <div className={css.appliedSubtitle}>
                Hey There, <br/>
                Thank you for your interest! Unfortunately, our Ambassador Program is no longer active, and this page is no longer accessible.<br/>
                We truly appreciate your enthusiasm and would still love to connect with you.<br/><br/>
                If you have any questions or would like to explore other opportunities with us, please don't hesitate to reach out to us directly.<br/>
                We look forward to hearing from you!
                </div>
              </div>
            </div>
          </div>
        )}
      </Page>
    </>
  );
};
