export const PublicCampaignStatus = {
  ACTIVE: 'active',
  DRAFT: 'draft',
  COMPLETED: 'completed',
  CLOSED: 'closed',
};

export const HEAR_FROM_OPTIONS = ['facebook', 'linkedin ', 'instagram', 'twitter', 'tiktok', 'referred by a friend', 'Found via search engine', 'others'];

export const PublicCampaignDealType = {
  PAID: 'Gifted & Paid',
  GIFTED: 'Gifted',
}
