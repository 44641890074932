import css from './TextField.module.css';

export const TextField = ({
  id = null,
  name = null,
  value = null,
  onChange = null,
  placeholder = null,
  type = 'text',
  errorMessage = null,
  disabled = false,
}) => {
  return (
    <div>
      <input
        id={id}
        type={type}
        name={name}
        value={value}
        onChange={(e) => onChange(e)}
        placeholder={placeholder}
        className={`${css.baseContainer} ${css.baseFont}`}
        disabled={disabled}
      />
      <div className={css.errorMessage}>{errorMessage}</div>
    </div>
  )
}
