import React from 'react';
import PropTypes from 'prop-types';
import IconSpinner from '../IconSpinner/IconSpinner';
import loading_animation from '../../assets/animations/loading_animation.gif';

import css from './Loading.module.css';

const Loading = ({ width, height, type = "spinner" }) => {
  return (
    <div className={css.loading} style={{ width: width, height: height }}>
      {type === 'spinner' && <IconSpinner />}
      {type === 'animation' && <img src={loading_animation} alt="Loading..." width={150} />}
    </div>
  );
};

Loading.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Loading.defaultProps = {
  width: '100%',
  height: '100%',
};

export default Loading;
