import { useFormik } from "formik";
import { TextAreaField } from "../../New/TextAreaField/TextAreaField";
import IconDisputeOrder from "../../IconDisputeOrder/IconDisputeOrder";
import ModalV2Standard from "../../ModalV2/ModalV2Standard";
import css from './TransactionDisputeDialog.module.css';
import { Button } from "../../New/Button/Button";
import Loading from "../../Loading/Loading";
import { useState } from "react";
import toast from "react-hot-toast";

export const TransactionDisputeDialog = ({
  open,
  onClose,
  onDispute,
}) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      disputeReason: '',
    },
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await onDispute(values.disputeReason);
        toast.success('Dispute report sent!');
        formik.resetForm();
        setSuccess(true);
      } catch (error) {
        console.error(error);
        toast.error('Failed to send dispute report');
      } finally {
        setLoading(false);
      }
    }
  });

  return (
    <ModalV2Standard
      isOpen={open}
      onClose={onClose}
      icon={<IconDisputeOrder />}
      width={550}
    >
      <div>
        {!success ? (
          <>
            <div className={css.textContainer}>
              <div className={css.headerText}>Did something go wrong?</div>
              <div>If you're not happy with your experience, please let the Collabreate team know what happened. We will try to solve the problem as soon as possible.</div>
            </div>
            <div className={css.inputContainer}>
              <div>Please explain the situation in detail</div>
              <TextAreaField value={formik.values.disputeReason} name={'disputeReason'} onChange={formik.handleChange} placeholder={'There was an issue with...'} />
            </div>
            <Button color='black' fullWidth className={css.btnSubmit} disabled={!formik.dirty || loading} onClick={formik.handleSubmit}>
              <div className={css.btnContent}>
                Submit
                {loading && (
                  <Loading />
                )}
              </div>
            </Button>
          </>
        ) : (
          <div className={css.textContainer}>
            <div className={css.headerText}>Dispute report sent!</div>
            <div>Thank you for letting us know. We will review the situation and take the necessary actions to solve the dispute.</div>
            <div>We will get in touch as soon as possible.</div>
          </div>
        )}

      </div>
    </ModalV2Standard>
  )
}
