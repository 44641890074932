import IconSpinner from "../IconSpinner/IconSpinner";
import css from './LoadingOverlayScreen.module.css';
import loading_animation from '../../assets/animations/loading_animation.gif';

const LoadingOverlayScreen = ({
  isLoading,
  type = "spinner",
}) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div className={css.base}>
      <div className={css.centerContainer}>
        {type === 'spinner' && <IconSpinner />}
        {type === 'animation' && <img src={loading_animation} alt="Loading..." width={150} />}
      </div>
    </div>
  )
};

export default LoadingOverlayScreen;
