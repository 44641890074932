import React, { useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  IconSocialMediaFacebook,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';
import startupLogo from '../../assets/startupLogo.png';

import css from './Footer.module.css';
import { useSelector } from 'react-redux';
import IconSocialMediaLinkedIn from '../IconSocialMediaLinkedIn/IconSocialMediaLinkedIn';
import IconSocialMediaTiktok from '../IconSocialMediaTiktok/IconSocialMediaTiktok';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ModalV2Simple from '../ModalV2/ModalV2Simple';

const renderSocialMediaLinks = intl => {
  const { siteInstagramPage, siteFacebookPage, siteLinkedInPage, siteTiktokPage } = config;
  // const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToFacebook = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToLinkedIn = intl.formatMessage({ id: 'Footer.goToLinkedIn' });
  const goToTiktok = intl.formatMessage({ id: 'Footer.goToTiktok' });

  // const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  // const twitterLink = siteTwitterPage ? (
  //   <ExternalLink
  //     key="linkToTwitter"
  //     href={siteTwitterPage}
  //     className={css.icon}
  //     title={goToTwitter}
  //   >
  //     <IconSocialMediaTwitter />
  //   </ExternalLink>
  // ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;

  const facebookLink = siteFacebookPage ? (
    <ExternalLink
      key="linkToFacebook"
      href={siteFacebookPage}
      className={css.icon}
      title={goToFacebook}
    >
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const linkedInLink = siteLinkedInPage ? (
    <ExternalLink
      key="linkToLinkedIn"
      href={siteLinkedInPage}
      className={css.icon}
      title={goToLinkedIn}
    >
      <IconSocialMediaLinkedIn />
    </ExternalLink>
  ) : null;

  const tiktokLink = siteLinkedInPage ? (
    <ExternalLink
      key="linkToTiktok"
      href={siteTiktokPage}
      className={css.icon}
      title={goToTiktok}
    >
      <IconSocialMediaTiktok />
    </ExternalLink>
  ) : null;

  return [tiktokLink, linkedInLink, instragramLink, facebookLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);
  const userId = useSelector(state => state?.user?.currentUser?.id?.uuid);

  const location = useLocation();
  const pathName = location.pathname;

  const [openModal, setOpenModal] = useState(false);
  const [mode, setMode] = useState(pathName.includes('/brands') ? 'client' : 'creator');

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.links}>
            <div className={css.infoLinks}>
              <div>
                <h4 className={css.listHeader}>
                  <FormattedMessage id="Footer.company" />
                </h4>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <div onClick={() => setOpenModal(true)} className={`${css.link} ${css.cursorPointer}`}>
                      <FormattedMessage id="Footer.toHowWorksPage" />
                    </div>
                    {/* <NamedLink name="BookDemoPage" params={{ role: 'client' }} className={css.link}>
                      <FormattedMessage id="Footer.toHowWorksPage" />
                    </NamedLink> */}
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="CategoriesPage" className={css.link}>
                      <FormattedMessage id="Footer.toFindLink" />
                    </NamedLink>
                  </li>
                  <br />
                </ul>
              </div>
              <div>
                <h4 className={css.listHeader}>
                  <FormattedMessage id="Footer.support" />
                </h4>
                <ul className={css.list}>
                  {/* <li className={css.listItem}>
                    <NamedLink name="HelpGeneralPage" className={css.link}>
                      <FormattedMessage id="Footer.helpCenter" />
                    </NamedLink>
                  </li> */}
                  <li className={css.listItem}>
                    <ExternalLink
                      href="mailto:hello@collabreate.co?subject=General Inquiry"
                      data-content="hello@collabreate.co"
                      data-type="mail"
                      className={css.link}
                    >
                      <FormattedMessage id="Footer.toContactPage" />
                    </ExternalLink>
                  </li>
                </ul>
              </div>
              <div>
                <h4 className={css.listHeader}>
                  <FormattedMessage id="Footer.legal" />
                </h4>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                      <FormattedMessage id="Footer.termsOfUse" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                      <FormattedMessage id="Footer.privacyPolicy" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="AccessibilityPage" className={css.legalLink}>
                      <FormattedMessage id="Footer.accessibility" />
                    </NamedLink>
                  </li>
                </ul>
              </div>
              <div className={css.extraLinks}>
                <div className={css.organization} id="organization">
                  {/*TO DO: uncomment when got new link */}
                  {/* <ExternalLink href="https://www.startupsg.gov.sg/profiles/44660"> */}
                  <img src={startupLogo} className={css.startupLogo} />
                  {/* </ExternalLink> */}
                </div>
              </div>
            </div>
            <div className={css.extraLinks}>
              <div className={css.organization} id="organization">
                <NamedLink name="LandingPage" className={css.logoLink}>
                  <Logo
                    format="desktop"
                    className={css.logo}
                    alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
                    footerLogo={true}
                  />
                </NamedLink>
                <div className={css.someLinks}>{socialMediaLinks}</div>
                <div className={css.organizationInfo}>
                  <p className={css.organizationCopyright}>
                    <FormattedMessage id="Footer.copyright" />
                  </p>
                </div>
              </div>
            </div>

            <div className={css.someLinksMobile}>
              {/* <ExternalLink
                href="https://www.startupsg.gov.sg/profiles/44660"
                className={css.mobileStartupLogoLink}
              > */}
              <div className={css.mobileStartupLogoLink}>
                <img src={startupLogo} className={css.startupLogo} />
              </div>

              {/* </ExternalLink> */}
              <NamedLink name="LandingPage" className={css.logoLink}>
                <Logo
                  format="desktop"
                  className={css.logo}
                  alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
                  footerLogo={true}
                />
              </NamedLink>
              <div className={css.someLinksContainer}>
                <div className={css.someLinks}>{socialMediaLinks}</div>
              </div>
              <div className={css.organizationInfo}>
                <p className={css.organizationCopyright}>
                  <FormattedMessage id="Footer.copyright" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalV2Simple
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        title={"How it works"}
        width={800}
      >
        <div>
          <div className={css.modeButtonContainer}>
            <div>I'm a</div>
            <div className={`${css.modeButton} ${mode === 'client' && css.modeButtonActive}`}  onClick={() => setMode('client')}>Brand/Agency</div>
            <div className={`${css.modeButton} ${mode === 'creator' && css.modeButtonActive}`} onClick={() => setMode('creator')}>Creator</div>
          </div>
        </div>
        <iframe
          className={css.video}
          src={mode === "client" ?
            "https://www.loom.com/embed/f9d6e00b5d364849a5c75e7f5fbdf64f?sid=8ceff8e2-fc9e-416d-8923-ca7abc384e7b?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true&hideEmbedSidebar=true&hide_controls=true" :
            "https://www.loom.com/embed/0e2fe36adf7d48489b28d1439d693e32?sid=17cab934-d90e-4e98-8ec6-68e330b61748?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true&hideEmbedSidebar=true&hide_controls=true" }
        />
      </ModalV2Simple>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
