import React from 'react';

import css from './index.module.css';

import Chip from '../../../../../components/Chip/Chip';

import virtualAssistant from '../../../../../assets/become-pro/virtual-assistant.webp';
import fireIcon from '../../../../../assets/icons/fire.svg';
import arrow_right from '../../../../../assets/become-pro/arrow-right.svg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSetAtom } from 'jotai';
import { openCreatorDemoDialogAtom } from '../../../../../atoms/creatordemo';

const VirtualAssistant = () => {
  const history = useHistory();
  const setOpenModal = useSetAtom(openCreatorDemoDialogAtom);

  return (
    <div className={css.wrapper}>
      <div className={css.content}>
        <Chip
          borderColor="#F3F4E4"
          backgroundColor="#F3F4E4"
          textColor="#292929"
          padding="4px 12px 4px 10px"
          mobilePadding="4px 10px 4px 8px"
        >
          <img src={fireIcon} />
          Coming soon
        </Chip>
        <div className={css.title}>Virtual Assistant</div>
        <div className={css.description}>
          Our talent manager will support all negotiations, communications and projects through our
          platform, allowing you to focus on what you do best - creating!
        </div>
        <div className={css.btnContainer}>
          <button className={css.btn_join} onClick={() => history.push('/login')}>
            Join now
            <img src={arrow_right} alt="right arrow" />
          </button>
          <button className={css.btnWhiteOutline} onClick={() => setOpenModal(true)}>
            Book a demo
          </button>
        </div>
      </div>
      <div className={css.imageContainer}>
        <img className={css.image} src={virtualAssistant} alt="virtual-assistant" />
      </div>
    </div>
  );
};

export default VirtualAssistant;
