import React from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  LayoutSideNavigation,
  LayoutWrapperAccountSideNav,
} from '../../components';
import { ProfileSettingsForm } from '../../forms';
import { TopbarContainer } from '../../containers';

import { updateProfile, uploadImage } from './ProfileSettingsPage.duck';
import css from './ProfileSettingsPage.module.css';
import classNames from 'classnames';

const onImageUploadHandler = (values, fn) => {
  const { id, imageId, file } = values;
  if (file) {
    fn({ id, imageId, file });
  }
};

export const ProfileSettingsPageComponent = props => {
  const {
    currentUser,
    currentUserListing,
    image,
    onImageUpload,
    onUpdateProfile,
    scrollingDisabled,
    updateInProgress,
    updateProfileError,
    uploadImageError,
    uploadInProgress,
    intl,
  } = props;

  const handleSubmit = values => {
    const {
      firstName,
      lastName,
      bio: rawBio,
      instagram,
      displayName,
      activity,
      tiktok,
      youtube,
      twitter,
      facebook,
      website,
    } = values;

    // Ensure that the optional bio is a string
    const bio = rawBio || '';

    const profile = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      bio,
      publicData: {
        displayName: displayName?.trim(),
        activity: activity,
        instagram: instagram?.trim(),
        tiktok: tiktok?.trim(),
        youtube: youtube?.trim(),
        twitter: twitter?.trim(),
        facebook: facebook?.trim(),
        website: website?.trim(),
      },
    };
    const uploadedImage = props.image;

    // Update profileImage only if file system has been accessed
    const updatedValues =
      uploadedImage && uploadedImage.imageId && uploadedImage.file
        ? { ...profile, profileImageId: uploadedImage.imageId }
        : profile;

    onUpdateProfile(updatedValues);
  };

  const user = ensureCurrentUser(currentUser);
  const { firstName, lastName, bio } = user.attributes.profile;
  const { isCreator } = user?.attributes?.profile?.protectedData || {};
  const { instagram, tiktok, youtube, twitter, facebook, website } =
    user?.attributes?.profile?.publicData || {};
  const { displayName, activity } = user?.attributes?.profile?.publicData || {};
  const profileImageId = user.profileImage ? user.profileImage.id : null;
  const profileImage = image || { imageId: profileImageId };

  const profileSettingsForm = user.id ? (
    <ProfileSettingsForm
      className={css.form}
      currentUser={currentUser}
      initialValues={{
        firstName,
        lastName,
        bio,
        displayName,
        activity,
        instagram,
        tiktok,
        youtube,
        twitter,
        facebook,
        website,
        profileImage: user.profileImage,
      }}
      profileImage={profileImage}
      onImageUpload={e => onImageUploadHandler(e, onImageUpload)}
      uploadInProgress={uploadInProgress}
      updateInProgress={updateInProgress}
      uploadImageError={uploadImageError}
      updateProfileError={updateProfileError}
      onSubmit={handleSubmit}
    />
  ) : null;

  const title = intl.formatMessage({ id: 'ProfileSettingsPage.title' });

  return (
    <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation containerClassName={css.sidebarContainer}>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="ProfileSettingsPage" />
          {/*<UserNav selectedPageName="ProfileSettingsPage" listing={currentUserListing} />*/}
        </LayoutWrapperTopbar>

        <LayoutWrapperAccountSideNav
          currentTab="ProfileSettingsPage"
          userProfile={true}
          isAvatar={true}
          currentUser={currentUser}
          isProUser={false}
        />

        <LayoutWrapperMain className={css.wrapperMain}>
          <div className={css.content}>
            <h1 className={css.header}>Settings</h1>
            <div className={css.switcherContainer}>
              <div name="ContactDetailsPage" className={css.switcher}>
                <NamedLink
                  name="ProfileSettingsPage"
                  className={classNames(css.switcherButton, css.active)}
                >
                  Edit profile
                </NamedLink>

                <NamedLink name="ContactDetailsPage" className={css.switcherButton}>
                  Contact details
                </NamedLink>
                <NamedLink name="PasswordChangePage" className={css.switcherButton}>
                  Password
                </NamedLink>
                {currentUserListing && (
                  <NamedLink name="StripePayoutPage" className={css.switcherButton}>
                    Payout details
                  </NamedLink>
                )}
                {!isCreator && (
                  <>
                    <NamedLink name="PaymentMethodsPage" className={css.switcherButton}>
                      Payment method
                    </NamedLink>
                    <a
                      className={css.switcherButton}
                      href={process.env.NODE_ENV === 'production' ?
                        'https://billing.stripe.com/p/login/8wMcOv05S89M3AYdQQ' :
                        'https://billing.stripe.com/p/login/test_8wMaHM9QK7Xh0GQ8ww'
                      }
                      target='_blank'
                    >
                      Billing
                    </a>
                  </>
                )}
              </div>
            </div>
            <div className={css.subHeaderContainer}>
              <div>
                <h2 className={css.subheader}>Edit profile</h2>
                <NamedLink
                  name="ProfilePage"
                  className={css.subheaderDescription}
                  params={{ id: currentUser?.id?.uuid || '0' }}
                >
                  View my Profile as others see it
                </NamedLink>
              </div>
              <div className={css.horizontalRule} />
              {profileSettingsForm}
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

ProfileSettingsPageComponent.defaultProps = {
  currentUser: null,
  currentUserListing: null,
  uploadImageError: null,
  updateProfileError: null,
  image: null,
};

ProfileSettingsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  currentUserListing: propTypes.ownListing,
  image: shape({
    id: string,
    imageId: propTypes.uuid,
    file: object,
    uploadedImage: propTypes.image,
  }),
  onImageUpload: func.isRequired,
  onUpdateProfile: func.isRequired,
  scrollingDisabled: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser, currentUserListing } = state.user;
  const {
    image,
    uploadImageError,
    uploadInProgress,
    updateInProgress,
    updateProfileError,
  } = state.ProfileSettingsPage;
  return {
    currentUser,
    currentUserListing,
    image,
    scrollingDisabled: isScrollingDisabled(state),
    updateInProgress,
    updateProfileError,
    uploadImageError,
    uploadInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onImageUpload: data => dispatch(uploadImage(data)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const ProfileSettingsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ProfileSettingsPageComponent);

export default ProfileSettingsPage;
