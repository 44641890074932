// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';

import ModalV2Base from './ModalV2Base';
import closeIcon from '../../assets/icons/close.svg';

import css from './ModalV2Simple.module.css';

const ModalV2Simple = ({ isOpen, onClose, children, width, title }) => {
  const renderContent = () => (
    <>
      <div className={css.modalHeader}>
        <div className={css.title}>{title}</div>

        <button className={css.closeButton} onClick={onClose}>
          <img src={closeIcon} alt="close" />
        </button>
      </div>
      <div className={css.modalContent}>{children}</div>
    </>
  );

  return <ModalV2Base isOpen={isOpen} width={width} renderContent={renderContent} />;
};

ModalV2Simple.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  width: PropTypes.number,
  title: PropTypes.string,
};

export default ModalV2Simple;
