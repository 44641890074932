import ModalV2Simple from "../../../ModalV2/ModalV2Simple";
import { Button } from "../../../New/Button/Button";
import css from './CreatorPromptTermsDialog.module.css';

export const CreatorPromptTermsDialog = ({
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <ModalV2Simple
      isOpen={open}
      onClose={onClose}
      width={500}
      title={"Accept Terms and Condition"}
    >
      <div className={css.wrapper}>
        <div className={css.text}>
          Please confirm that you are agreeable to the client's brief and our terms and conditions.
        </div>
        <div className={css.subText}>
          <b>Note:</b> You will be able to dispute the order if you are unable to deliver, but you will not be able to cancel the asset delivery without the team's approval.
        </div>
        <div className={css.btnWrapper}>
          <Button color="black" onClick={onConfirm}>Confirm</Button>
          <Button color="gray" onClick={onClose}>Cancel</Button>
        </div>
      </div>
    </ModalV2Simple>
  )
};
