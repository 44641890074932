import React from 'react';
import { LearnerAndProOptionStyle } from '../styles/LearnerAndProOption.style';
import _, { set } from 'lodash';
import { PinkButton } from '../../components/Button/Button';
import { Button } from '../../components/New/Button/Button';

const LearnerAndProOption = props => {
  const {
    selectedSignupProcess,
    setSelectedSignupProcess,
    _onCreateButtonClick,
    setLoginPage,
  } = props;

  const LERNER_PRO = [
    {
      signupSelectCode: 2,
      icon: require('../assest/proIcon.png'),
      text: 'Creator',
      para: `Looking to sell my services`,
    },
    {
      signupSelectCode: 1,
      icon: require('../assest/lernerIcon.png'),
      text: 'Agency',
      para: `Looking for creators`,
    },
    {
      signupSelectCode: 3,
      icon: require('../assest/lernerIcon.png'),
      text: 'Brand',
      para: `Looking for creators`,
    },
  ];

  return (
    <LearnerAndProOptionStyle>
      <div className="mainLernerPro_class">
        <div className="chooseSection_class commonWidth">
          <div className="contentButton_section">
            <div className="content">
              <h2>I am a... </h2>
            </div>
            <div className="learnerPro_Buttons">
              {_.map(LERNER_PRO, r => (
                <div
                  className="borderClass"
                  key={r?.text}
                  onClick={() => {
                    if (selectedSignupProcess === r?.signupSelectCode) {
                      setSelectedSignupProcess(null);
                    } else {
                      setSelectedSignupProcess(r?.signupSelectCode);
                    }
                  }}
                >
                  <div className="icon">
                    <div>
                      <input
                        type="checkbox"
                        checked={selectedSignupProcess === r?.signupSelectCode}
                      />
                    </div>
                  </div>
                  <div className="text">
                    <p>{r?.text}</p>
                    <span>{r?.para}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="actionSection">
            <Button
              disabled={!selectedSignupProcess}
              onClick={() => _onCreateButtonClick('flow2')}
            >
              Create account
            </Button>
            {/* <PinkButton
              type="button"
              disabled={!selectedSignupProcess}
              onClick={() => _onCreateButtonClick('flow2')}
            >
              Create account
            </PinkButton> */}
            <p>
              Already have an account?{' '}
              <a
                onClick={() => {
                  setLoginPage(true);
                  props?.history.push('/login');
                }}
              >
                Log in
              </a>{' '}
            </p>
          </div>
        </div>
        <div className="imageSection">
          <img src={require('../assest/sign-up.webp')} alt="image1" />
          <div className="imageContent">
            <div>
              <h3>Connect, Create and Earn</h3>
              <span>Connect and collaborate to produce authentic and engaging content</span>
            </div>
          </div>
        </div>
      </div>
    </LearnerAndProOptionStyle>
  );
};

export default LearnerAndProOption;
