import React from 'react';
import css from './index.module.css';

import mentorship from '../../../../../assets/become-pro/mentorship.webp';
import arrow_right from '../../../../../assets/become-pro/arrow-right.svg';
import { useSetAtom } from 'jotai';
import { openCreatorDemoDialogAtom } from '../../../../../atoms/creatordemo';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Mentorship = () => {
  const history = useHistory();
  const setOpenModal = useSetAtom(openCreatorDemoDialogAtom);

  return (
    <div className={css.wrapper}>
      <div className={css.content}>
        <div className={css.title}>Creator Coach</div>
        <div className={css.description}>
          Maximize your growth with personalized 1:1 coaching and expert social media audits. Get tailored strategies, actionable insights, and performance-driven tips to enhance your content and online presence.
        </div>
        <div className={css.btnContainer}>
          <button className={css.btn_join} onClick={() => history.push('/login')}>
            Join now
            <img src={arrow_right} alt="right arrow" />
          </button>
          <button className={css.btnWhiteOutline} onClick={() => setOpenModal(true)}>
            Book a demo
          </button>
        </div>
      </div>
      <div className={css.imageContainer}>
        <img className={css.image} src={mentorship} alt="mentorship" />
      </div>
    </div>
  );
};

export default Mentorship;
