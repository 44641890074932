import axiosInstance from "../axios";

export default function useChatHook () {
  const fetchUnreadMessages = async (id, userType) => {
    try {
      const result = await axiosInstance.get(`/user/messages/unread/${id}`);
      const { data } = result.data;

      const unreadMessages = [];
      data.map((chatroom) => {
        chatroom.messages.map((message) => {
          if (message.readAt === null && message.userId !== id) unreadMessages.push(message);
        });
      });

      return unreadMessages;
    } catch (error) {
      console.error(error);
    }
  }

  const fetchChatroomsWithUnreadMessages = async (id) => {
    try {
      const result = await axiosInstance.get(`/user/messages/unread/${id}`);
      const { data } = result.data;

      return data;
    } catch (error) {
      console.error(error);
    }
  }

  const fetchUnreadChatroomMessages = async (id, userType) => {
    try {
      const result = await axiosInstance.get(`/user/messages/unread/${id}`);
      const { data } = result.data;

      const chatroomsWithUnreadMessages = [];
      data.map((chatroom) => {
        chatroom.messages.some((message) => message.readAt === null && message.userId !== id) && chatroomsWithUnreadMessages.push(chatroom);
      });

      return chatroomsWithUnreadMessages;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    fetchUnreadMessages,
    fetchUnreadChatroomMessages,
    fetchChatroomsWithUnreadMessages,
  }
}
