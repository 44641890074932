import React, { useEffect, useState } from 'react';
import {
  Page,
} from '../../../../components';
import css from './ViewShortlistPage.module.css';
import { Button } from '../../../../components/New/Button/Button';
import axiosInstance from '../../../../axios';
import { TextField } from '../../../../components/New/TextField/TextField';
import toast from 'react-hot-toast';
import { ArrowPagination } from '../../../../components/PaginationCollection/ArrowPagination/ArrowPagination';
import { camelToLabel, formatDateString } from '../../../../util/string';
import { Chip } from '../../../../components/NewChip/Chip';
import instagramIcon from '../../../../assets/icons/instagramLogo.svg';
import tiktokIcon from '../../../../assets/icons/tiktokLogo.svg';
import LoadingOverlayScreen from '../../../../components/LoadingOverlayScreen/LoadingOverlayScreen';
import { ApplicantNotesDialog } from '../../../../components/Dialogs/ApplicantNotesDialog/ApplicantNotesDialog';
import { set } from 'lodash';

const tableHeaders = [
  { key: 'creator', label: 'Creators' },
  { key: 'socialMedia', label: 'Socials' },
  { key: 'approval', label: 'Client approval status' },
  { key: 'usageDate', label: 'Last edited' },
  { key: 'actions', label: 'Actions' }
];

export const ViewShortlistPage = ({ params }) => {
  const { id } = params;
  const [page, setPage] = useState('auth');
  const [campaign, setCampaign] = useState(null);
  const [accessData, setAccessData] = useState(null);
  const [emailAuth, setEmailAuth] = useState('');
  const [shortlistApplicants, setShortlistApplicants] = useState([]);
  const [globalLoading, setGlobalLoading] = useState(false);

  const iconMap = {
    tiktok: tiktokIcon,
    instagram: instagramIcon,
  }

  const fetchCampaign = async () => {
    try {
      setGlobalLoading(true);
      const response = await axiosInstance.get(`/public-campaigns/detail/${id}`);
      const { error, message, data } = response.data;
      if (error) {
        return;
      }

      if (data.shareShortlistStatus === 'closed' || data.shareShortlistStatus === null) {
        setPage('no-access');
      }

      setCampaign(data);

      const temp = data.applicants.filter((applicant) => applicant.applicationStatus === 'shortlist');
      for (const applicant of temp) {
        const userDetail = await fetchSharetribeUserDetail(applicant.creatorId);
        applicant.instagramLink = userDetail?.attributes.profile.publicData.instagram || null;
        applicant.tiktokLink = userDetail?.attributes.profile.publicData.tiktok || null;
      }
      setShortlistApplicants(temp);
    } catch (error) {
      console.error(error);
    } finally {
      setGlobalLoading(false);
    }
  }


  const fetchSharetribeUserDetail = async (id) => {
    try {
      const response = await axiosInstance.get(`/sharetribe/user/detail/${id}`);
      const { error, message, data } = response.data;

      if (!error) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCampaign();
  }, []);

  const onClickConfirmEmail = async () => {
    const exist = campaign.shortlistAccess.find((access) => access.email === emailAuth);
    if (exist) {
      setAccessData(exist);
    } else {
      //create new access
      const response = await axiosInstance.post(`/campaigns/share-shortlist/create/${id}`, {
        email: emailAuth,
        name: emailAuth,
        company: '',
      });
      const { error, message, data } = response.data;
      if (error) {
        toast.error(message);
        return;
      }

      setAccessData(data);
    }
    setPage('has-access');
  }

  const onClickChangeApplicantStatus = async (applicantId, status) => {
    try {
      const response = await axiosInstance.post('/campaigns/share-shortlist/applicant/update', {
        applicantId,
        status
      });
      const { error } = response.data;
      if (error) {
        toast.error("An error occurred while updating applicant status");
        return;
      }
      setShortlistApplicants(shortlistApplicants.map((applicant) => {
        if (applicant.id === applicantId) {
          return {
            ...applicant,
            approvalStatus: status,
            updatedAt: new Date()
          }
        }
        return applicant;
      }));
      toast.success("Applicant status updated successfully");
    } catch (error) {
      console.error(error);
    }
  }

  const itemsPerPage = 20;
  const totalPages = Math.ceil(shortlistApplicants.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const onPageChange = (newCurrentPage) => {
    setCurrentPage(newCurrentPage);
  }

  // Calculate the applicants to display for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentApplicants = shortlistApplicants.slice(startIndex, endIndex);

  const [openNotesDialog, setOpenNotesDialog] = useState(false);
  const [applicantData, setApplicantData] = useState(null);
  const onClickOpenNotesDialog = (applicant) => {
    setApplicantData(applicant);
    setOpenNotesDialog(true);
  }

  if (!campaign) {
    return null;
  }

  return (
    <>
      <Page
        title={"Shared Shortlist"}
        scrollingDisabled={false}
      >
        <div className={css.root}>
          <LoadingOverlayScreen isLoading={globalLoading} type='animation' />
          {page === 'auth' && (
            <div className={css.screen}>
              <div className={css.authContainer}>
                <div className={css.authTitle}>{campaign.clientName} shared {campaign.name} Shortlist with you</div>
                <div>Enter your email to get access to the shortlist.</div>
                <div className={css.authFormContainer}>
                  <TextField value={emailAuth} onChange={(e) => setEmailAuth(e.target.value)} placeholder={'Email address'}/>
                  <Button fullWidth onClick={onClickConfirmEmail}>Confirm</Button>
                </div>
              </div>
            </div>
          )}
          {page === 'no-access' && (
            <div className={css.screen}>
              <div className={css.noAccessContainer}>
                <div className={css.noAccessTitle}>Access Denied</div>
                <div>
                  It looks like your access to the {campaign.name} Shortlist has been revoked.<br/> To regain access, please contact {campaign.clientName} and request permission to view the shortlist.
                </div>
              </div>
            </div>
          )}
          {page === 'has-access' && (
            <div className={css.hasAccessScreen}>
              <div className={css.authTitle}>{campaign.name} Shortlist</div>
              <div>
                <table className={css.table}>
                  <thead>
                    <tr>
                      {tableHeaders.map((header) => (
                        <th key={header.key} className={css.textLeft}>{header.label}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {currentApplicants.map((applicant, index) => (
                      <tr key={`tr-${applicant.id}-${index}`}>
                        <td>
                          <div className={css.creatorContainer}>
                            <img src={applicant.creatorAvatar} alt="Creator" className={css.creatorAvatar} />
                            <div>{applicant.creatorName}</div>
                          </div>
                        </td>
                        <td>
                          <div className={css.socialMediaContainer}>
                            {applicant.instagramLink && <a href={applicant.instagramLink} target='_blank'>
                              <img src={iconMap.instagram} alt="Social Media" className={css.socialMediaIcon} />
                            </a>}
                            {applicant.tiktokLink && <a href={applicant.tiktokLink} target='_blank'>
                              <img src={iconMap.tiktok} alt="Social Media" className={css.socialMediaIcon} />
                            </a>}
                          </div>
                        </td>
                        <td>
                          <div>
                            <Chip color={applicant.approvalStatus === 'pending' ? 'warning' : applicant.approvalStatus === 'approved' ? 'success' : 'error'} children={camelToLabel(applicant.approvalStatus)} />
                          </div>
                        </td>
                        <td>
                          <div>{formatDateString(applicant.updatedAt)}</div>
                        </td>
                        <td>
                          <div className={css.actionButtonContainer}>
                            <button className={css.actionButton} onClick={() => onClickChangeApplicantStatus(applicant.id, 'approved')}>Approve</button>
                            <button className={css.actionButton} onClick={() => onClickChangeApplicantStatus(applicant.id, 'declined')}>Decline</button>
                            <button className={css.actionButton} onClick={() => onClickOpenNotesDialog(applicant)}>
                              Notes <div className={css.btnBadge}>{applicant.applicantNotes.length}</div>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                    {/* pagination */}
                    {currentApplicants.length > 0 ? (
                      <tr>
                        <td colSpan={tableHeaders.length}>
                          <ArrowPagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={newCurrentPage => onPageChange(newCurrentPage)}
                          />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={tableHeaders.length}>
                          <div className={css.noApplicantsText}>No applicants to display</div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <ApplicantNotesDialog
            open={openNotesDialog}
            onClose={() => setOpenNotesDialog(false)}
            applicantId={applicantData?.id}
            applicantName={applicantData?.creatorName}
            accessId={accessData?.id}
            userId={undefined}
            onSuccess={() => fetchCampaign()}
          />
        </div>
      </Page>
    </>
  );
};
