import { bool } from 'prop-types';
import DesktopToolbar from './DesktopToolbar/DesktopToolbar';
import MobileToolbar from './MobileToolbar/MobileToolbar';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { hasCurrentUserErrors, sendVerificationEmail } from '../../ducks/user.duck';
import { authenticationInProgress, logout } from '../../ducks/Auth.duck';
import { pathByRouteName } from '../../util/routes';
import config from '../../config';
import { withRouter } from 'react-router-dom';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

const Toolbar = props => {
  const isAuthenticated = props.isAuthenticated;
  const routes = useRouteConfiguration();

  const handleLogout = () => {
    const { onLogout, history } = props;

    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routes);

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (config.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }
    });
  };

  if (props.hideOnAuthenticated && isAuthenticated) {
    return (
      <MobileToolbar
        isAuthenticated={isAuthenticated}
        currentUserHasListings={props.currentUserHasListings}
        currentUserListing={props.currentUserListing}
        currentUserListingFetched={props.currentUserListingFetched}
        currentUser={props.currentUser}
        onLogout={handleLogout}
        notificationCount={props.notificationCount}
        currentPage={props.currentPage}
        isDark={props.isDark}
      />
    );
  }

  return (
    <>
      <MobileToolbar
        isAuthenticated={isAuthenticated}
        currentUserHasListings={props.currentUserHasListings}
        currentUserListing={props.currentUserListing}
        currentUserListingFetched={props.currentUserListingFetched}
        currentUser={props.currentUser}
        onLogout={handleLogout}
        notificationCount={props.notificationCount}
        currentPage={props.currentPage}
        isDark={props.isDark}
        showNav={props.showNav}
        showNavButtons={props.showNavButtons}
      />
      <DesktopToolbar isAuthenticated={isAuthenticated} onLogout={handleLogout} isDark={props.isDark} showNav={props.showNav} showNavButtons={props.showNavButtons}/>
    </>
  );
};

Toolbar.defaultProps = {
  hideOnAuthenticated: false,
  isDark: false,
  showNav: true,
  showNavButtons: true,
};

Toolbar.propTypes = {
  hideOnAuthenticated: bool,
  isDark: bool,
  showNav: bool,
  showNavButtons: bool,
};

const mapStateToProps = state => {
  // Topbar needs isAuthenticated
  const { isAuthenticated, logoutError, authScopes } = state.Auth;
  // Topbar needs user info.
  const {
    currentUser,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUserHasOrders,
    currentUserNotificationCount: notificationCount,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  } = state.user;
  const hasGenericError = !!(logoutError || hasCurrentUserErrors(state));
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUserHasOrders,
    notificationCount,
    isAuthenticated,
    authScopes,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    hasGenericError,
  };
};

const mapDispatchToProps = dispatch => ({
  onLogout: historyPush => dispatch(logout(historyPush)),
  //
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Toolbar);
