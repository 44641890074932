import { useEffect, useState } from "react";
import css from './ApplicantNotesDialog.module.css';
import axiosInstance from "../../../axios";
import ModalV2Simple from "../../ModalV2/ModalV2Simple"
import Loading from "../../Loading/Loading";
import { TextField } from "../../New/TextField/TextField";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { Button } from "../../New/Button/Button";
import toast from "react-hot-toast";
import { formatDateString } from "../../../util/string";

export const ApplicantNotesDialog = ({
  open,
  onClose,
  applicantId,
  applicantName,
  userId = undefined,
  accessId = undefined,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [notes, setNotes] = useState([]);

  const formik = useFormik({
    initialValues: {
      note: ''
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      note: Yup.string().required('Notes are required')
    }),
    onSubmit: async (values) => {
      try {
        setSendLoading(true);
        const response = await axiosInstance.post('/campaigns/share-shortlist/applicant/notes/create', {
          applicantId,
          note: values.note,
          accessId,
          userId
        });

        const { error, message } = response.data;
        if (error) {
          toast.error(message);
          return;
        }

        formik.resetForm();
        await fetchApplicantNotes();
        await onSuccess();
        toast.success(message);
      } catch (error) {
        console.error(error);
      } finally {
        setSendLoading(false);
      }
    }
  })

  const fetchApplicantNotes = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/campaigns/share-shortlist/applicant/notes/list/${applicantId}`);
      const { data } = response.data;
      setNotes(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (applicantId) {
      fetchApplicantNotes();
    }
  }, [applicantId]);

  if (!applicantId) {
    return null;
  }

  return (
    <ModalV2Simple
      isOpen={open}
      onClose={onClose}
      title={`Notes on ${applicantName}`}
      width={600}
    >
      <div className={css.root}>
        <div className={css.list}>
          {loading && <div className={css.chatLoadingContainer}><Loading /></div>}
          {!loading && (
            <>
              {notes.length === 0 && <div>No notes found</div>}
              {notes.map((note) => {
                return (
                  <div className={css.itemContainer}>
                    <div className={css.profileContainer}>
                      <div className={css.profileImageContainer}>
                        {note.user && (<img src={note.user.avatar} className={css.profileImage}/>)}
                        {!note.user && (<div className={css.profileText}>{note.shortlistAccess.name.slice(0,2).toUpperCase()}</div>)}
                      </div>
                      <div className={css.profileGrid}>
                        <div><strong>{note.user ? note.user.displayName : note.shortlistAccess.name}</strong></div>
                        <div className={`${css.textRight} ${css.subtitle}`}>{formatDateString(note.createdAt)}</div>
                        <div className={css.subtitle}>{note.user ? note.user.email : note.shortlistAccess.email}</div>
                      </div>
                    </div>
                    <div>{note.note}</div>
                  </div>
                );
              })}
            </>
          )}
        </div>
        <div className={css.inputContainer}>
          <div className={css.flexGrow}>
            <TextField name={'note'} value={formik.values.note} onChange={formik.handleChange} placeholder={'type notes'} />
          </div>
          <div>
            <Button color="black" size="medium" onClick={formik.handleSubmit} disabled={sendLoading}>{sendLoading ? (<Loading />) : ('Send')}</Button>
          </div>
        </div>
        <div className={css.errorMessage}>{formik.errors.note}</div>
      </div>
    </ModalV2Simple>
  )
}
